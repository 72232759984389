import * as actionTypes from "../../actions/myclass-information/mentor/actionTypes";
import produce from "immer";

const initialState = {
  classMentor: null,
  error: null,
  loading: null,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_MENTOR_START:
        draft.loading = true;
        draft.error = null;
        break;

      case actionTypes.GET_MENTOR_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.GET_MENTOR_SUCCESS:
        draft.loading = false;
        draft.classMentor = action.classMentor;
        break;
      default:
    }
  });

export default reducer;
