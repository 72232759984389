import * as actionTypes from '../../actions/badges-teamlead/actionTypes'
import produce from 'immer'

const initialState = {
    badges: null,
    error: null,
    loading: false,
    badge: null,

    form: {
      controls: [
       {
          label: "ID",
          value: '',
          inputType: "input",
          type: "text",
          multiline: false,
          validators: {
                      
          },
          valid: false,
          touched: false,
        },
     
     {
          label: "Description",
          value: '',
          inputType: "input",
          type: "text",
          multiline: true,
          validators: {
                      
          },
          required: false,
          valid: false,
          touched: false,
        },
       {
          label: "",
          multiline: false,
          value: '',
          inputType: "input",
          type: "file",
          validators: {
                      
          },
          required: true,
          valid: false,
          touched: false,
        }
      ],
      isValid: false,
      error: null,
    }
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.GET_BADGES_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_BADGES_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_BADGES_SUCCESS):
                draft.loading = false

                // populate state form controls
                draft.badges = action.badges
                break

                  //GET SPECIFIC
      case actionTypes.GET_BADGE_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.GET_BADGE_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.badge = null;
        break;
      case actionTypes.GET_BADGE_SUCCESS:
        draft.loading = false;
        draft.modules = action.modules;
        draft.class = action.class;
        // populate state form controls
        if(action.info){
        draft.badge = action.info;
        }
        break;

            case(actionTypes.ADD_BADGE_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.ADD_BADGE_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.ADD_BADGE_SUCCESS):
                draft.adding = true
                draft.loading = false
                if(action.data){
                    draft.badges.payload.push(action.data)
                }
                if(draft.form){
                    Object.keys(draft.form.controls).forEach(controlName => {
                        draft.form.controls[controlName].value=null
                    })
                }                
                break
           // UPDATE
      case actionTypes.PUT_BADGE_START:
        draft.loading = true;
        draft.edited = false;
        draft.error = null;
        break;
      case actionTypes.PUT_BADGE_FAIL:
        draft.loading = false;
        draft.edited = false;
        draft.error = action.error;
        break;
      case actionTypes.PUT_BADGE_SUCCESS:
        draft.loading = false;
        draft.responceData = action.responceData;
        draft.edited = true;
        break;
         //DELETE
      case actionTypes.DELETE_BADGE_START:
        draft.loading = true;
        draft.added = false;
        draft.deleted = false;
        draft.error = null;
        break;
      case actionTypes.DELETE_BADGE_FAIL:
        draft.loading = false;
        draft.deleted = false;
        draft.error = action.error;
        break;
      case actionTypes.DELETE_BADGE_SUCCESS:
        draft.loading = false;
        draft.responceData = action.responceData;
        draft.deleted = true;
        break;
            
            default:
        }
    })

export default reducer
