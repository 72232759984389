import * as actionTypes from '../../actions/institutes/actionTypes'
import { inputChanged } from '../../../utility/forms/inputChanged'
import produce from 'immer'


const initialState = {
    form: {
      controls: {
        id: {
          label: "ID",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        name: {
          label: "Name",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        },
        description: {
          label: "Description",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        usefulLinksPage: {
          label: "Useful Links",
          value: null,
          inputType: "select",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        },
        helpPage: {
          label: "Help",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        },
        creationDate: {
          label: "Creation Date",
          value: null,
          inputType: "input",
          type: "date",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        }
      },
      isValid: false,
      error: null,
    },
    loading: false,
    error: null,
    edited: false,
    institute: null
  };

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            // update the input value in the state
            case(actionTypes.INPUT_VALUE_CHANGED_INSTITUTE_UPDATE):
              inputChanged(draft, action.name, action.value)
              break

                //GET ONE USER
            case actionTypes.GET_INSTITUTE_START:
              draft.loading = true;
              draft.error = null;
              draft.edited = false;
              break;
  
            case actionTypes.GET_INSTITUTE_FAIL:
              draft.loading = false;
              draft.error = action.error;
              break;

            case actionTypes.GET_INSTITUTE_SUCCESS:
              draft.loading = false;
              draft.institute = action.institute
              break;

            //UPDATE
            case actionTypes.PUT_INSTITUTE_START:
                draft.loading = true;
                draft.error = null;
                draft.edited = false;
                break;
            case actionTypes.PUT_INSTITUTE_FAIL:
                draft.loading = false;
                draft.error = action.error;
                break;
            case actionTypes.PUT_INSTITUTE_SUCCESS:
              draft.loading = false;
              draft.edited = true;
                // populate state form controls
                if(action.info){
                  Object.keys(action.info).forEach((controlName) => {
                    draft.form.controls[controlName].value = action.info[controlName]
                    ? action.info[controlName]
                    : null;
                  });
                }
                break;
            default:
        }
    })

 
export default reducer;