import React, { Component } from 'react';
import css from './LandingPageLayout.module.scss'

// import components
import Toolbar from './Toolbar/Toolbar'

class LandingPageLayout extends Component {

    render() { 
        return ( 
            <div className={css.Layout}>
                <Toolbar></Toolbar>
                <main>{this.props.children}</main>
            </div>
        );
    }
}
 
export default LandingPageLayout;