import React, {Component, Fragment} from 'react';
import css from './ContactForm.module.scss';

// import components
import Input from '../../../../components/forms/landing-page/Input/Input'
import Spinner from '../../../../components/ui/spinners/landing-page/Spinner/Spinner'
import Button from '../../../../components/ui/buttons/landing-page/Button/Button'

// imports from packages
import produce from 'immer'
import { validateInput } from '../../../../utility/forms/validateInput'
import { FiMail, GoPencil } from 'react-icons/all'

// redux store
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/landingPage/Contact/contact';

class ContactForm extends Component {
    state = {
        contactForm: {
            controls: {
                email:{
                    inputType: 'input',
                    type: 'email',
                    value : null,
                    validators: {
                        required: true,
                        isEmail: true
                    },
                    valid: false,
                    touched: false,
                    label: <FiMail />
                },
                title: {
                    inputType: 'input',
                    type: 'text',
                    value: null,
                    validators: {
                        required: true
                    },
                    valid: false,
                    touched: false,
                    label: <GoPencil />
                },
                content: {
                    inputType: 'textarea',
                    // type: 'text',
                    value: null,
                    validators: {
                        required: true
                    },
                    valid: false,
                    touched: false,
                    rows:5,
                    label: <GoPencil />
                }

            },
            formIsValid: false
        }
    }

    inputChangedHandler = (controlName, value) => {
        this.setState(
            produce(draft => {
                const control = draft.contactForm.controls[controlName];
                control.value = value;
                control.valid = validateInput(control.validators, value);
                control.touched = true;

                let formIsValid = true;

                Object.values(draft.contactForm.controls).forEach((config) => {
                    formIsValid = formIsValid && config.valid;
                })

                draft.contactForm.formIsValid = formIsValid;
            })
        );
    }

    onSubmitHandler = (event) => {
        event.preventDefault();

        const controls = this.state.contactForm.controls;
        const email = controls.email.value;
        const title = controls.title.value;
        const content = controls.content.value;

        this.props.onSubmit(email, title, content);
    }

    render() {
        const controls = Object.keys(this.state.contactForm.controls).map((controlName, key) => {
            const config = this.state.contactForm.controls[controlName];
            return (
                <Input key={key}
                    inputType={config.inputType}
                    type={config.type}
                    name={controlName}
                    changed={this.inputChangedHandler}
                    label={config.label}
                    required={config.required}
                    placeholder={controlName}
                    value={config.value}
                    valid={config.valid}
                    rows={config.rows}
                    touched={config.touched} />
            );
        });

        let error = null;

        if (this.props.error) {
            error = <p className={css.Error}>{this.props.error}</p>
        }

        let form = <Spinner style={{marginTop: '2em'}}/>

        if (!this.props.loading) {
            form = (
                <Fragment>

                    {controls}
                    {error}
                    <Button disabled={!this.state.contactForm.formIsValid}>Submit</Button>
                </Fragment>
                
            )
        }

        return ( 
            <Fragment>
                <form className={css.ContactForm} onSubmit={this.onSubmitHandler}>
                {form}
                </form>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        loading: state.auth.loading,
        registrationSuccessful: state.auth.registrationSuccessful,
        registrationMessage: state.auth.registrationMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (email, title, content) => dispatch(actions.submit(email, title, content))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);