export const DELETE_PREVIOUS_EDUCATION = 'DELETE_PREVIOUS_EDUCATION'
export const DELETE_PREVIOUS_EDUCATION_START = 'DELETE_PREVIOUS_EDUCATION_START'
export const DELETE_PREVIOUS_EDUCATION_FAIL = 'DELETE_PREVIOUS_EDUCATION_FAIL'
export const DELETE_PREVIOUS_EDUCATION_SUCCESS = 'DELETE_PREVIOUS_EDUCATION_SUCCESS'

export const GET_PREVIOUS_EDUCATION = 'GET_PREVIOUS_EDUCATION'
export const GET_PREVIOUS_EDUCATION_START = 'GET_PREVIOUS_EDUCATION_START'
export const GET_PREVIOUS_EDUCATION_FAIL = 'GET_PREVIOUS_EDUCATION_FAIL'
export const GET_PREVIOUS_EDUCATION_SUCCESS = 'GET_PREVIOUS_EDUCATION_SUCCESS'

export const POST_PREVIOUS_EDUCATION = 'POST_PREVIOUS_EDUCATION'
export const POST_PREVIOUS_EDUCATION_START = 'POST_PREVIOUS_EDUCATION_START'
export const POST_PREVIOUS_EDUCATION_FAIL = 'POST_PREVIOUS_EDUCATION_FAIL'
export const POST_PREVIOUS_EDUCATION_SUCCESS = 'POST_PREVIOUS_EDUCATION_SUCCESS'

export const PUT_PREVIOUS_EDUCATION = 'PUT_PREVIOUS_EDUCATION'
export const PUT_PREVIOUS_EDUCATION_START = 'PUT_PREVIOUS_EDUCATION_START'
export const PUT_PREVIOUS_EDUCATION_FAIL = 'PUT_PREVIOUS_EDUCATION_FAIL'
export const PUT_PREVIOUS_EDUCATION_SUCCESS = 'PUT_PREVIOUS_EDUCATION_SUCCESS'

export const INPUT_VALUE_CHANGED_PREVIOUS_EDUCATION_CREATE = 'INPUT_VALUE_CHANGED_PREVIOUS_EDUCATION_CREATE'
export const INPUT_VALUE_CHANGED_PREVIOUS_EDUCATION_UPDATE = 'INPUT_VALUE_CHANGED_PREVIOUS_EDUCATION_UPDATE'