import * as actionTypes from "../../actions/myclass-information/overview/actionTypes";
import produce from "immer";

const initialState = {
  classOverview: null,
  error: null,
  loading: null,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_OVERVIEW_START:
        draft.loading = true;
        draft.error = null;
        break;

      case actionTypes.GET_OVERVIEW_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.GET_OVERVIEW_SUCCESS:
        draft.loading = false;
        draft.classOverview = action.classOverview;
        break;
      default:
    }
  });

export default reducer;
