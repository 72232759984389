import React from 'react';
import css from './Spinner.module.scss'

const spinner = (props) => {
    return (
        <div className={css.wrapper} style={props.style}>
            <div className={css.loader}>
                <div className={[css.inner, css.one].join(' ')}></div>
                <div className={[css.inner, css.two].join(' ')}></div>
                <div className={[css.inner, css.three].join(' ')}></div>
            </div>
        </div>

    );
}

export default spinner;