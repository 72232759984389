import React, { Component, Fragment } from "react";
import css from "./LoginForm.module.scss";

// import components
import Input from "../../../../components/forms/landing-page/Input/Input";
import Spinner from "../../../../components/ui/spinners/landing-page/Spinner/Spinner";
import Button from "../../../../components/ui/buttons/landing-page/Button/Button";

// imports from packages
import produce from "immer";
import { validateInput } from "../../../../utility/forms/validateInput";
import { FaLock, FiMail } from "react-icons/all";
import { withRouter, Route } from "react-router-dom";

// redux store
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/auth";
import ForgottenPasswordForm from "../ForgottenPasswordForm/ForgottenPasswordForm";

class LoginForm extends Component {
  state = {
    forgottenPassword: false,
    loginForm: {
      controls: {
        email: {
          inputType: "input",
          type: "email",
          value: null,
          validators: {
            required: true,
            isEmail: true,
          },
          valid: false,
          touched: false,
          label: <FiMail />,
        },
        password: {
          inputType: "input",
          type: "password",
          value: null,
          validators: {
            required: true,
            minLength: 8,
          },
          valid: false,
          touched: false,
          label: <FaLock />,
        },
      },
      formIsValid: false,
    },
  };

  inputChangedHandler = (controlName, value) => {
    this.setState(
      produce((draft) => {
        const control = draft.loginForm.controls[controlName];
        control.value = value;
        control.valid = validateInput(control.validators, value);
        control.touched = true;

        let formIsValid = true;

        Object.values(draft.loginForm.controls).forEach((config) => {
          formIsValid = formIsValid && config.valid;
        });

        draft.loginForm.formIsValid = formIsValid;
      })
    );
  };

  onSubmitHandler = (event) => {
    event.preventDefault();

    const controls = this.state.loginForm.controls;
    const email = controls.email.value;
    const password = controls.password.value;

    this.props.onLogin(email, password);
  };
  onForgottenPasswordHandler = () => {
    this.setState({forgottenPassword: !this.state.forgottenPassword});
  }

  render() {
    const controls = Object.keys(this.state.loginForm.controls).map(
      (controlName, key) => {
        const config = this.state.loginForm.controls[controlName];
        return (
          <Input
            key={key}
            inputType={config.inputType}
            type={config.type}
            name={controlName}
            changed={this.inputChangedHandler}
            label={config.label}
            required={config.required}
            placeholder={controlName}
            value={config.value}
            valid={config.valid}
            touched={config.touched}
          />
        );
      }
    );

    let error = null;

    if (this.props.error) {
      error = <p className={css.Error}>{this.props.error}</p>;
    }

    let form = <Spinner style={{ marginTop: "2em" }} />;

    if (!this.props.loading && !this.state.forgottenPassword) {
      form = (
        <Fragment>
        <form className={css.LoginForm} onSubmit={this.onSubmitHandler}>
          {controls}
          {error}
          <Button disabled={!this.state.loginForm.formIsValid}>Login</Button>
          <p className={css.ForgottenPassword} onClick={this.onForgottenPasswordHandler}>
            <a>Forgot password?</a>
          </p>
        </form>
        </Fragment>
      );
    }
    else if(!this.props.loading && this.state.forgottenPassword){
      form = <ForgottenPasswordForm onForgottenPasswordHandler={this.onForgottenPasswordHandler} />
    }

    return (
      <Fragment>
          {form}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    loading: state.auth.loading,
    registrationSuccessful: state.auth.registrationSuccessful,
    registrationMessage: state.auth.registrationMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password) => dispatch(actions.login(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm));
