import * as actionTypes from '../../actions/classes-teacher/feedback/actionTypes'
import produce from 'immer'
import {feedbackMockData} from './feedbackMockData'

const initialState = {
    feedback:null,
    feedbackList: feedbackMockData,
    error: null,
    loading: false,
    form: {
        controls: {
       
        subject:
          {
            label: "Subject",
            value: '',
            inputType: "input",
            type: "text",
            multiline: false,
          },
        
        },
        isValid: false,
        error: null,
      }
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.GET_FEEDBACK_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_FEEDBACK_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_FEEDBACK_SUCCESS):
                draft.loading = false
                let tempDataCopy = [...draft.feedbackList]
                console.log(action.userID)
                tempDataCopy = tempDataCopy.filter(grade=> grade.studentID === action.userID)
                
              
                draft.feedback = tempDataCopy
                break

                case(actionTypes.ON_CHANGE_INPUT_VALUE):
                if(action.name && action.data){
                    draft.form.controls[action.name].value = draft.data
                }
                break

                case(actionTypes.POST_FEEDBACK_START):
            draft.loading = true;
            draft.error = null;
            break
        case(actionTypes.POST_FEEDBACK_FAIL):
            draft.loading = false;
            draft.error = action.error;
            break
        case(actionTypes.POST_FEEDBACK_SUCCESS):
        
            draft.adding = true;
            draft.loading = false;
            if(action.data){
                draft.feedbackList.push(action.data)
                draft.feedback.push(action.data)

            }
            if(draft.form){
                Object.keys(draft.form.controls).forEach(controlName => {
                    draft.form.controls[controlName].value=null
                })
            }
            break
            
            default:
        }
    })

export default reducer
