import * as actionTypes from "../../actions/myclass-information/studentlists/actionTypes";
import produce from "immer";

const initialState = {
  classStudentLists: null,
  responseData: null,
  error: null,
  loading: null,
  added: false,
  deleted: false,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_STUDENTLISTS_START:
        draft.added = false;
        draft.deleted = false;
        draft.loading = true;
        draft.error = null;
        break;

      case actionTypes.GET_STUDENTLISTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.GET_STUDENTLISTS_SUCCESS:
        draft.loading = false;
        draft.classStudentLists = action.classStudentLists;
        break;
      //Post
      case actionTypes.ADD_STUDENTLISTS_START:
        draft.loading = true;
        draft.error = null;
        break;

      case actionTypes.ADD_STUDENTLISTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.ADD_STUDENTLISTS_SUCCESS:
        draft.loading = false;
        draft.responseData = action.responseData;
        draft.added = true;
        break;
      //DELETE
      case actionTypes.DELETE_STUDENTLISTS_START:
        draft.loading = true;
        draft.error = null;
        break;

      case actionTypes.DELETE_STUDENTLISTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.DELETE_STUDENTLISTS_SUCCESS:
        draft.loading = false;
        draft.responseData = action.responseData;
        draft.deleted = true;
        break;  
    }
  });

export default reducer;
