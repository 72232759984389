export const GET_FEEDBACK_START = 'GET_FEEDBACK_START'
export const GET_FEEDBACK_FAIL = 'GET_FEEDBACK_FAIL'
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS'

export const POST_FEEDBACK_START = 'POST_FEEDBACK_START'
export const POST_FEEDBACK_FAIL = 'POST_FEEDBACK_FAIL'
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS'

export const PUT_FEEDBACK_START = 'PUT_FEEDBACK_START'
export const PUT_FEEDBACK_FAIL = 'PUT_FEEDBACK_FAIL'
export const PUT_FEEDBACK_SUCCESS = 'PUT_FEEDBACK_SUCCESS'

export const DELETE_FEEDBACK_START = 'DELETE_FEEDBACK_START'
export const DELETE_FEEDBACK_FAIL = 'DELETE_FEEDBACK_FAIL'
export const DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS'

export const ON_CHANGE_INPUT_VALUE = 'ON_CHANGE_INPUT_VALUE'