import * as actionTypes from '../../actions/personal-information/general-information/profilephoto/actionTypes'
import produce from 'immer'

const initialState = {
    profilephoto: null,
    error: null,
    loading: null
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case actionTypes.GET_PROFILE_PHOTO_START:
                draft.loading = true;
                draft.error = null;
                break;
    
            case actionTypes.GET_PROFILE_PHOTO_FAIL:
                draft.loading = false;
                draft.error = action.error;
                break;

            case actionTypes.GET_PROFILE_PHOTO_SUCCESS:
                draft.loading = true;
                draft.profilephoto = action.profilephoto
                break;
            default:
        }
    })

 
export default reducer;