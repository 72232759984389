import * as actionTypes from '../../actions/classes-teacher/announcements/actionTypes'
import produce from 'immer'

const initialState = {
    classAnnouncements : null,
    userClassAnnouncements:null,
    error: null,
    loading: false
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.GET_CLASSANNOUNCEMENTS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_CLASSANNOUNCEMENTS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_CLASSANNOUNCEMENTS_SUCCESS):
                draft.loading = false

                // populate state form controls
                draft.classAnnouncements = action.classAnnouncements
                
                break

            //Get User Specific announcements
            case(actionTypes.GET_USERCLASSANNOUNCEMENTS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_USERCLASSANNOUNCEMENTS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_USERCLASSANNOUNCEMENTS_SUCCESS):
                draft.loading = false
                draft.userClassAnnouncements = action.userClassAnnouncements
                
                break

                case(actionTypes.DELETE_USERCLASSANNOUNCEMENT_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.DELETE_USERCLASSANNOUNCEMENT_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.DELETE_USERCLASSANNOUNCEMENT_SUCCESS):
                draft.loading = false
                draft.classAnnouncements = action.classAnnouncements
                
                break

            case(actionTypes.POST_CLASSANNOUNCEMENT_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.POST_CLASSANNOUNCEMENT_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.POST_CLASSANNOUNCEMENT_SUCCESS):
                draft.loading = false

                // populate state form controls
                if(draft.classAnnouncements){
                    draft.classAnnouncements.push(action.data);
                }
                
                break
            
            default:
        }
    })

export default reducer
