export const GET_TEACHERLISTS = "GET_TEACHERLISTS";
export const GET_TEACHERLISTS_START = "GET_TEACHERLISTS_START";
export const GET_TEACHERLISTS_FAIL = "GET_TEACHERLISTS_FAIL";
export const GET_TEACHERLISTS_SUCCESS = "GET_TEACHERLISTS_SUCCESS";

export const ADD_TEACHERLISTS_START = "ADD_TEACHERLISTS_START";
export const ADD_TEACHERLISTS_FAIL = "ADD_TEACHERLISTS_FAIL";
export const ADD_TEACHERLISTS_SUCCESS = "ADD_TEACHERLISTS_SUCCESS";

export const PUT_TEACHERLISTS_START = "PUT_TEACHERLISTS_START";
export const PUT_TEACHERLISTS_FAIL = "PUT_TEACHERLISTS_FAIL";
export const PUT_TEACHERLISTS_SUCCESS = "PUT_TEACHERLISTS_SUCCESS";

export const DELETE_TEACHERLISTS_START = "DELETE_TEACHERLISTS_START";
export const DELETE_TEACHERLISTS_FAIL = "DELETE_TEACHERLISTS_FAIL";
export const DELETE_TEACHERLISTS_SUCCESS = "DELETE_TEACHERLISTS_SUCCESS";