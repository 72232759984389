import React from 'react';
import css from './LearningOutcomes.module.scss'
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { functionForGrabbingAllPluginDecorators } from '../../../../../../utility/functionForGrabbingAllPluginDecorators';

const  LearningOutcomes =(props)=> {
    let body = null;
    let contentState = null;
    let editorState = null;
    if(props.data){
        contentState = convertFromRaw(JSON.parse(props.data));
        let decorator = functionForGrabbingAllPluginDecorators();
        editorState = EditorState.createWithContent(contentState, decorator);
        body = (
                <div>
                    <Editor editorState={editorState} readOnly={true} />
                </div>
        )
    }
        return ( 
            <div className={css.LearningOutcomes}>
                <h1>LearningOutcomes</h1>
                {body}
            </div>
         );
    }

 
export default LearningOutcomes;