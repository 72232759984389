export const GET_CLASSES_START = 'GET_CLASSES_START';
export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS';
export const GET_CLASSES_FAIL = 'GET_CLASSES_FAIL';

export const ADD_CLASS_START = 'ADD_CLASS_START';
export const ADD_CLASS_SUCCESS = 'ADD_CLASS_SUCCESS';
export const ADD_CLASS_FAIL = 'ADD_CLASS_FAIL';

export const DELETE_CLASS_START = 'DELETE_CLASS_START';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';
export const DELETE_CLASS_FAIL = 'DELETE_CLASS_FAIL';

export const GET_CLASS_START = 'GET_CLASS_START';
export const GET_CLASS_SUCCESS = 'GET_CLASS_SUCCESS';
export const GET_CLASS_FAIL = 'GET_CLASS_FAIL';

export const PUT_CLASS_START = 'PUT_CLASS_START';
export const PUT_CLASS_SUCCESS = 'PUT_CLASS_SUCCESS';
export const PUT_CLASS_FAIL = 'PUT_CLASS_FAIL';

export const INPUT_VALUE_CHANGED_CLASSES_CREATE = 'INPUT_VALUE_CHANGED_CLASSES_CREATE';
export const INPUT_VALUE_CHANGED_CLASSES_UPDATE = 'INPUT_VALUE_CHANGED_CLASSES_UPDATE';