import * as actionTypes from '../../actions/student-stats/actionTypes'
import produce from 'immer'

const initialState = {
    studentStats : null,
    error: null,
    loading: false
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.GET_STUDENT_STATS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_STUDENT_STATS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_STUDENT_STATS_SUCCESS):
                draft.loading = false

                // populate state form controls
                draft.studentStats = action.studentStats
                
                break
            
            default:
        }
    })

export default reducer
