export const GET_GRADES_START = 'GET_GRADES_START'
export const GET_GRADES_FAIL = 'GET_GRADES_FAIL'
export const GET_GRADES_SUCCESS = 'GET_GRADES_SUCCESS'

export const POST_GRADE_START = 'POST_GRADES_START'
export const POST_GRADE_FAIL = 'POST_GRADES_FAIL'
export const POST_GRADE_SUCCESS = 'POST_GRADES_SUCCESS'

export const PUT_GRADES_START = 'PUT_GRADES_START'
export const PUT_GRADES_FAIL = 'PUT_GRADES_FAIL'
export const PUT_GRADES_SUCCESS = 'PUT_GRADES_SUCCESS'

export const DELETE_GRADES_START = 'DELETE_GRADES_START'
export const DELETE_GRADES_FAIL = 'DELETE_GRADES_FAIL'
export const DELETE_GRADES_SUCCESS = 'DELETE_GRADES_SUCCESS'