import * as actionTypes from '../../actions/classes-teacher/grades/actionTypes'
import produce from 'immer'

const initialState = {
    grades: [],
    gradeList: [ // TODO: this data should be received from the database
        {   id: 0, 
            classID: 1,
            teacherID: 14,
            studentID: 13,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            credits: 3,
            grade: null,
            gradeNote: "",
            pass: null,

            gradingModel: {
                id: 'GPA',
                name: 'GPA_SCALE',
                description:`Possible Grades are:
                - Unsatisfactory
                - Satisfactory
                - Good
                - Outstanding `,
                options: [{code:"G", title: 'Good', pass: true}, {code:"S", title: 'Satisfactory', pass: true}, {code:"U",title: 'Unsatisfactory', pass: false}]
               
                
              }
        },
        {   
            id: 2, 
            classID: 1,
            teacherID: 14,
            studentID: 13,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            credits: 3,
            grade: "O",
            gradeNote: "",
            pass: true,
    
            gradingModel: {
                id: 'GPA',
                name: 'GPA_SCALE',
                description:`Possible Grades are:
                - Unsatisfactory
                - Satisfactory
                - Good
                - Outstanding `,
                options: [{code:"G", title: 'Good', pass: true}, {code:"S", title: 'Satisfactory', pass: true}, {code:"U",title: 'Unsatisfactory', pass: false}]
               
                
              }
        },
        {   
            id: 3, 
            classID: 1,
            teacherID: 14,
            studentID: 13,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            credits: 3,
            grade: "S",
            gradeNote: "very attentive student",
            pass: true,
    
            gradingModel: {
                id: 'GPA',
                name: 'GPA_SCALE',
                description:`Possible Grades are:
                - Unsatisfactory
                - Satisfactory
                - Good
                - Outstanding `,
                options: [{code:"G", title: 'Good', pass: true}, {code:"S", title: 'Satisfactory', pass: true}, {code:"U",title: 'Unsatisfactory', pass: false}]
               
                
              }
        },
        {  
            id: 4, 
            classID: 1,
            teacherID: 14,
            studentID: 13,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            credits: 3,
            grade: "O",
            gradeNote: "",
            pass: true,
    
            gradingModel: {
                id: 'GPA',
                name: 'GPA_SCALE',
                description:`Possible Grades are:
                - Unsatisfactory
                - Satisfactory
                - Good
                - Outstanding `,
                options: [{code:"G", title: 'Good', pass: true}, {code:"S", title: 'Satisfactory', pass: true}, {code:"U",title: 'Unsatisfactory', pass: false}]
               
                
              }
        },
        {   
            id: 5, 
            classID: 1,
            teacherID: 14,
            studentID: 13,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            credits: 3,
            grade: null,
            gradeNote: "",
            pass: null,
    
            gradingModel: {
                id: 'GPA',
                name: 'GPA_SCALE',
                description:`Possible Grades are:
                - Unsatisfactory
                - Satisfactory
                - Good
                - Outstanding `,
                options: [{code:"G", title: 'Good', pass: true}, {code:"S", title: 'Satisfactory', pass: true}, {code:"U",title: 'Unsatisfactory', pass: false}]
               
                
              }
        },
        {   
            id: 6, 
            classID: 1,
            teacherID: 14,
            studentID: 13,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            credits: 3,
            grade: "O",
            gradeNote: "fsklfjslk",
            pass: true,
            
            gradingModel: {
                id: 'GPA',
                name: 'GPA_SCALE',
                description:`Possible Grades are:
                - Unsatisfactory
                - Satisfactory
                - Good
                - Outstanding `,
                options: [{code:"G", title: 'Good', pass: true}, {code:"S", title: 'Satisfactory', pass: true}, {code:"U",title: 'Unsatisfactory', pass: false}]
               
                
              }
    
        },
        {   
            id: 7, 
            classID: 1,
            teacherID: 10,
            studentID: 14,
            teacherFullName: "Dimitar Petrov",
            moduleCode: "test01",
            moduleName: "test_01",
            credits: 3,
            grade: null,
            gradeNote: "",
            pass: null,
    
            gradingModel: {
                id: 'GPA',
                name: 'GPA_SCALE',
                description:`Possible Grades are:
                - Unsatisfactory
                - Satisfactory
                - Good
                - Outstanding `,
                options: [{code:"G", title: 'Good', pass: true}, {code:"S", title: 'Satisfactory', pass: true}, {code:"U",title: 'Unsatisfactory', pass: false}]
               
                
              }
    
        },
        {   
            id: 8, 
            classID: 1,
            teacherID: 14,
            studentID: 14,
            teacherFullName: "Dimitar Petrov",
            moduleCode: "test02",
            moduleName: "test_02",
            credits: 3,
            grade: "S",
            pass: true,

            gradeNote: "barely sufficient",
    
            gradingModel: {
                id: 'GPA',
                name: 'GPA_SCALE',
                description:`Possible Grades are:
                - Unsatisfactory
                - Satisfactory
                - Good
                - Outstanding `,
                options: [{code:"G", title: 'Good', pass: true}, {code:"S", title: 'Satisfactory', pass: true}, {code:"U",title: 'Unsatisfactory', pass: false}]
    
              }
    
        },
        {   
            id: 9, 
            classID: 1,
            teacherID: 14,
            studentID: 14,
            teacherFullName: "Dimitar Petrov",
            moduleCode: "test03",
            moduleName: "test_03",
            credits: 3,
            grade: null,
            pass: null,
            gradeNote: "",
            gradingModel: {
                id: 'CI',
                name: 'Complete/Incomplete',
                description: `There are two options for grading. Either Complete or incomplete`,
                options: [ {code:"C", title: 'Complete(C)', pass: true}, {code:"I",title: 'Incomplete', pass: false}]
    
              }
    
        },
        {   
            id: 10, 
            classID: 1,
            teacherID: 14,
            studentID: 14,
            teacherFullName: "Dimitar Petrov",
            moduleCode: "EXO",
            moduleName: "Experience Design Orienting",
            credits: 3,
            grade: "C",
            pass: true,

            gradeNote: "",
            gradingModel: {
                id: 'CI',
                name: 'Complete/Incomplete',
                description: `There are two options for grading. Either Complete or incomplete`,
                options: [ {code:"C", title: 'Complete(C)', pass: true}, {code:"I",title: 'Incomplete', pass: false}]
    
              }
    
        },
        {   
            id: 11, 
            classID: 1,
            teacherID: 14,
            studentID: 14,
            teacherFullName: "Dimitar Petrov",
            moduleCode: "IEO",
            moduleName: "Infrastructure Orienting",
            credits: 3,
            grade: "C",
            pass: true,

            gradeNote: "",
            gradingModel: {
                id: 'CI',
                name: 'Complete/Incomplete',
                description: `There are two options for grading. Either Complete or incomplete`,
                options: [ {code:"C", title: 'Complete(C)', pass: true}, {code:"I",title: 'Incomplete', pass: false}]
    
              }
    
        },
        {   
            id: 12, 
            classID: 1,
            teacherID: 14,
            studentID: 14,
            teacherFullName: "Dimitar Petrov",
            moduleCode: "IEA",
            moduleName: "Infrastructure Advanced",
            credits: 3,
            grade: "I",
            pass: false,
            gradeNote: "",
            gradingModel: {
                id: 'CI',
                name: 'Complete/Incomplete',
                description: `There are two options for grading. Either Complete or incomplete`,
                options: [ {code:"C", title: 'Complete(C)', pass: true}, {code:"I",title: 'Incomplete', pass: false}]
    
              }
    
        }
    ],
    error: null,
    loading: false,
    edited: false,
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.GET_GRADES_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_GRADES_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_GRADES_SUCCESS):
                draft.loading = false
                let tempDataCopy = [...draft.gradeList]
                tempDataCopy = tempDataCopy.filter(grade=> grade.studentID === action.studentId)
                // populate state form controls
                draft.grades = tempDataCopy
                break

            case(actionTypes.POST_GRADE_START):
                draft.loading = true;
                draft.error = null;
                break
            case(actionTypes.POST_GRADE_FAIL):
                draft.loading = false;
                draft.error = action.error;
                break
            case(actionTypes.POST_GRADE_SUCCESS):
            
                draft.adding = true;
                draft.loading = false;
                if(action.data){
                    let tempDataCopy = [...draft.gradeList]
                    let indexGrade = null;

                    tempDataCopy.forEach((grade, index)=>{
                        console.log(grade);
                        console.log(index);

                        if(grade.moduleCode=== action.moduleId && grade.studentID === action.studentId){
                            indexGrade = index
                        }
                    })

                    tempDataCopy[indexGrade].grade = action.data.selectedGrade
                    tempDataCopy[indexGrade].gradeNote = action.data.gradeNote

                    // draft.gradeList[indexGrade].grade = action.data.selectedGrade
                    // draft.gradeList[indexGrade].gradeNote = action.data.gradeNote

                    draft.gradeList = [...tempDataCopy];

                    //Run Get grades again
                    tempDataCopy = [...draft.gradeList]
                    tempDataCopy = tempDataCopy.filter(grade=> grade.studentID === action.studentId)
                    // populate state form controls
                    draft.grades = tempDataCopy

    
                }
                if(draft.form){
                    Object.keys(draft.form.controls).forEach(controlName => {
                        draft.form.controls[controlName].value=null
                    })
                }
                break
            default:
        }
    })

export default reducer
