import React, { Component } from 'react';
import css from './UserPage.module.scss';
import jsPDF from 'jspdf';

// import components
import Card from '../../../../../components/ui/cards/Card/Card';
import Spinner from '../../../../../components/ui/spinners/main-application/Spinner/Spinner';
import Button from '@material-ui/core/Button';
import EditForm from './EditForm/EditForm'
import ProfileImg from '../../../../../assets/images/TeamLead.svg';


import { connect } from 'react-redux'
import { getUser, inputValueChangedUser } from '../../../../../store/actions/users';

export class UserPage extends Component {
    state = {
        user: null,
        isEditing: false,
        formSubmited: false,
    }
    componentDidMount() {
        if(this.props.id){
            this.props.onGetUser(this.props.id);
        }
    }
    //rerender the component after a user edit 
    componentDidUpdate(prevProps){
        if(prevProps.edited !== this.props.edited){
            this.props.onGetUser(this.props.id);
            this.setState({user: this.props.onGetUser(this.props.id),
            isEditing: false});
        }
    }
    //Toggle between Edit form and view
    changeEditModeHandler =() => {
        this.setState({isEditing: !this.state.isEditing});
    }
    /////export to pdf
    checkNull = (value)=>{//// check value name, if it is null it return - 
        if(value===null){
        return '-';
        }else{
        return value;
        }
    }
    jsPdfGenerator = () => {
        const temp = []
        Object.values(this.props.form.controls).map((info, key) => {
        if(key<16){
            const res = info.label + ': ' + this.checkNull(info.value);
            temp.push(res)
        }})
    
        const doc = new jsPDF();
        doc.text(temp, 15, 15);
        doc.save(`${this.props.form.controls.firstName.value}${this.props.form.controls.lastName.value}`);
        // Save the Data
    }
    
    render() {
        //basic read-only view
        let form = <Spinner />;
        if(!this.props.loading){
            form = Object.values(this.props.form.controls).map((info, key) => {
                return (
                    <p key={key}>
                        <span data-test="viewForm" className={css.Key}>{info.label}:</span><span className={css.Value}>{info.value}</span><br />
                    </p>
                )
            })
        }
        //toggle between edit form and read only view
        if (this.state.isEditing || this.props.isEditing) {
            // form = <EditForm form={this.props.form} inputChanged={this.props.onInputValueChanged} changeEditMode={this.props.editModeChanged} />
            form = <EditForm form={this.props.form} inputChanged={this.props.onInputValueChanged} id={this.props.id} />
        }
        

        return (
            <React.Fragment>
                <Card title="User information">
                    <img src={ProfileImg} alt="Profile portrait" />
                    <div className={css.form}>
                        {form}
                    </div>
                </Card>
                <Card>
                    <div className={css.Controls}>
                        <Button variant="contained" color="primary" className={css.Control} onClick={this.props.goBack}>Go back</Button>
                        <Button variant="contained" color="primary" className={css.Control} onClick={this.changeEditModeHandler}>EditUser</Button>
                        <Button variant="contained" color="secondary" className={css.Control} onClick={() => this.props.delete(this.props.id)}>Delete user</Button>
                        <Button variant="contained" color="secondary" className={css.Control} onClick={this.jsPdfGenerator}>Print</Button>
                    </div>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        userID: state.auth.userID,
        form: state.users.form,
        deleted: state.users.daleted,
        edited: state.users.edited,
        loading: state.users.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInputValueChanged: (inputName, inputValue) => dispatch(inputValueChangedUser(inputName, inputValue)),
        onGetUser: (id) => dispatch(getUser(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);