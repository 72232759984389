export const GET_CLASSANNOUNCEMENTS_START = 'GET_CLASSANNOUNCEMENTS_START';
export const GET_CLASSANNOUNCEMENTS_SUCCESS = 'GET_CLASSANNOUNCEMENTS_SUCCESS';
export const GET_CLASSANNOUNCEMENTS_FAIL = 'GET_CLASSANNOUNCEMENTS_FAIL';

export const POST_CLASSANNOUNCEMENT_START = 'POST_CLASSANNOUNCEMENTS_START';
export const POST_CLASSANNOUNCEMENT_FAIL = 'POST_CLASSANNOUNCEMENTS_FAIL';
export const POST_CLASSANNOUNCEMENT_SUCCESS = 'POST_CLASSANNOUNCEMENTS_SUCCESS';

export const GET_USERCLASSANNOUNCEMENTS_START = 'GET_USERCLASSANNOUNCEMENTS_START';
export const GET_USERCLASSANNOUNCEMENTS_FAIL = 'GET_CLASSANNOUNCEMENTS_FAIL';
export const GET_USERCLASSANNOUNCEMENTS_SUCCESS = 'GET_USERCLASSANNOUNCEMENTS_SUCCESS';

export const DELETE_USERCLASSANNOUNCEMENT_START = 'DELETE_USERCLASSANNOUNCEMENT_START';
export const DELETE_USERCLASSANNOUNCEMENT_FAIL = 'DELETE_USERCLASSANNOUNCEMENT_FAIL';
export const DELETE_USERCLASSANNOUNCEMENT_SUCCESS = 'DELETE_USERCLASSANNOUNCEMENT_SUCCESS';

