export const GET_PERSONALNOTES_START = 'GET_PERSONALNOTES_START'
export const GET_PERSONALNOTES_FAIL = 'GET_PERSONALNOTES_FAIL'
export const GET_PERSONALNOTES_SUCCESS = 'GET_PERSONALNOTES_SUCCESS'

export const POST_PERSONALNOTES_START = 'POST_PERSONALNOTES_START'
export const POST_PERSONALNOTES_FAIL = 'POST_PERSONALNOTES_FAIL'
export const POST_PERSONALNOTES_SUCCESS = 'POST_PERSONALNOTES_SUCCESS'

export const PUT_PERSONALNOTES_START = 'PUT_PERSONALNOTES_START'
export const PUT_PERSONALNOTES_FAIL = 'PUT_PERSONALNOTES_FAIL'
export const PUT_PERSONALNOTES_SUCCESS = 'PUT_PERSONALNOTES_SUCCESS'

export const DELETE_PERSONALNOTES_START = 'DELETE_PERSONALNOTES_START'
export const DELETE_PERSONALNOTES_FAIL = 'DELETE_PERSONALNOTES_FAIL'
export const DELETE_PERSONALNOTES_SUCCESS = 'DELETE_PERSONALNOTES_SUCCESS'