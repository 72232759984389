import React from 'react';
import css from './NavigationItem.module.scss';

import { withRouter, NavLink } from 'react-router-dom';

const navigationItem = (props) => {
    let navigationItemStyles = [css.NavigationItem];

    if (props.location.pathname === props.link) {
        navigationItemStyles.push(css.Active);
    }

    return (
        <NavLink to={props.link} exact={props.exact}>
            <li className={navigationItemStyles.join(' ')}>
                <div className={css.Text}>
                    {props.text}
                </div>
            </li>
        </NavLink>
    );
}

export default withRouter(navigationItem);