export const DELETE_WORKING_EXPERIENCE = 'DELETE_WORKING_EXPERIENCE'
export const DELETE_WORKING_EXPERIENCE_START = 'DELETE_WORKING_EXPERIENCE_START'
export const DELETE_WORKING_EXPERIENCE_FAIL = 'DELETE_WORKING_EXPERIENCE_FAIL'
export const DELETE_WORKING_EXPERIENCE_SUCCESS = 'DELETE_WORKING_EXPERIENCE_SUCCESS'

export const GET_WORKING_EXPERIENCE = 'GET_WORKING_EXPERIENCE'
export const GET_WORKING_EXPERIENCE_START = 'GET_WORKING_EXPERIENCE_START'
export const GET_WORKING_EXPERIENCE_FAIL = 'GET_WORKING_EXPERIENCE_FAIL'
export const GET_WORKING_EXPERIENCE_SUCCESS = 'GET_WORKING_EXPERIENCE_SUCCESS'

export const POST_WORKING_EXPERIENCE = 'POST_WORKING_EXPERIENCE'
export const POST_WORKING_EXPERIENCE_START = 'POST_WORKING_EXPERIENCE_START'
export const POST_WORKING_EXPERIENCE_FAIL = 'POST_WORKING_EXPERIENCE_FAIL'
export const POST_WORKING_EXPERIENCE_SUCCESS = 'POST_WORKING_EXPERIENCE_SUCCESS'

export const PUT_WORKING_EXPERIENCE = 'PUT_WORKING_EXPERIENCE'
export const PUT_WORKING_EXPERIENCE_START = 'PUT_WORKING_EXPERIENCE_START'
export const PUT_WORKING_EXPERIENCE_FAIL = 'PUT_WORKING_EXPERIENCE_FAIL'
export const PUT_WORKING_EXPERIENCE_SUCCESS = 'PUT_WORKING_EXPERIENCE_SUCCESS'

export const INPUT_VALUE_CHANGED_WORKING_EXPERIENCE_CREATE = 'INPUT_VALUE_CHANGED_WORKING_EXPERIENCE_CREATE'
export const INPUT_VALUE_CHANGED_WORKING_EXPERIENCE_UPDATE = 'INPUT_VALUE_CHANGED_WORKING_EXPERIENCE_UPDATE'