export const DELETE_INTERESTS = 'DELETE_INTERESTS'
export const DELETE_INTERESTS_START = 'DELETE_INTERESTS_START'
export const DELETE_INTERESTS_FAIL = 'DELETE_INTERESTS_FAIL'
export const DELETE_INTERESTS_SUCCESS = 'DELETE_INTERESTS_SUCCESS'

export const GET_INTERESTS = 'GET_INTERESTS'
export const GET_INTERESTS_START = 'GET_INTERESTS_START'
export const GET_INTERESTS_FAIL = 'GET_INTERESTS_FAIL'
export const GET_INTERESTS_SUCCESS = 'GET_INTERESTS_SUCCESS'

export const POST_INTERESTS = 'POST_INTERESTS'
export const POST_INTERESTS_START = 'POST_INTERESTS_START'
export const POST_INTERESTS_FAIL = 'POST_INTERESTS_FAIL'
export const POST_INTERESTS_SUCCESS = 'POST_INTERESTS_SUCCESS'

export const PUT_INTERESTS = 'PUT_INTERESTS'
export const PUT_INTERESTS_START = 'PUT_INTERESTS_START'
export const PUT_INTERESTS_FAIL = 'PUT_INTERESTS_FAIL'
export const PUT_INTERESTS_SUCCESS = 'PUT_INTERESTS_SUCCESS'

export const INPUT_VALUE_CHANGED_INTERESTS = 'INPUT_VALUE_CHANGED_INTERESTS'