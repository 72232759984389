import React, { Component, Fragment } from 'react';
import css from './RegisterForm.module.scss'

// import components
import Input from '../../../../components/forms/landing-page/Input/Input'
import Spinner from '../../../../components/ui/spinners/landing-page/Spinner/Spinner'
import Button from '../../../../components/ui/buttons/landing-page/Button/Button'

// imports from packages
import produce from 'immer'
import { validateInput } from '../../../../utility/forms/validateInput'
import { MdPeople, FaLock, FiMail, GoPencil } from 'react-icons/all'
import {withRouter} from 'react-router-dom'

// redux store
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/auth';

class RegisterForm extends Component {
    state = {
        registerForm: {
            controls: {
                firstName: {
                    inputType: 'input',
                    type: 'text',
                    value: null,
                    validators: {
                        required: true
                    },
                    valid: false,
                    touched: false,
                    label: <GoPencil />
                },
                lastName: {
                    inputType: 'input',
                    type: 'text',
                    value: null,
                    validators: {
                        required: true
                    },
                    valid: false,
                    touched: false,
                    label: <GoPencil />
                },
                email: {
                    inputType: 'input',
                    type: 'email',
                    value: null,
                    validators: {
                        required: true,
                        isEmail: true
                    },
                    valid: false,
                    touched: false,
                    label: <FiMail />
                },
                password: {
                    inputType: 'input',
                    type: 'password',
                    value: null,
                    validators: {
                        required: true,
                        minLength: 8
                    },
                    valid: false,
                    touched: false,
                    label: <FaLock />
                },
                confirmPassword: {
                    inputType: 'input',
                    type: 'password',
                    value: null,
                    validators: {
                        required: true,
                        minLength: 8
                    },
                    valid: false,
                    touched: false,
                    label: <FaLock />
                },
                role: {
                    inputType: 'select',
                    value: 'Student',
                    validators: {
                        required: true
                    },
                    options: [{
                        text: 'Student', 
                        value: 'Student'
                    }, 
                    {
                        text: 'Teacher',
                        value: 'Teacher'
                    },{
                        text: "Team Lead",
                        value: "TeamLead"
                    }],
                    valid: true,
                    touched: true,
                    label: <MdPeople />
                }
            },
            formIsValid: false,
            error: null
        }
    }

    inputChangedHandler = (controlName, value) => {
        this.setState(
            produce(draft => {
                const control = draft.registerForm.controls[controlName];
                control.value = value;
                control.valid = validateInput(control.validators, value);
                control.touched = true;

                let formIsValid = true;

                Object.values(draft.registerForm.controls).forEach((config) => {
                    formIsValid = formIsValid && config.valid;
                })

                draft.registerForm.formIsValid = formIsValid;

                if (draft.registerForm.controls.password.value !== draft.registerForm.controls.confirmPassword.value) {
                    draft.registerForm.error = "Entered passwords don't match"
                }
                else draft.registerForm.error = null
            })
        );
    }

    onSubmitHandler = (event) => {
        event.preventDefault();

        const controls = this.state.registerForm.controls;
        const firstName = controls.firstName.value;
        const lastName = controls.lastName.value;
        const email = controls.email.value;
        const password = controls.password.value;
        const role = controls.role.value;

        this.props.onRegistration(firstName, lastName, email, password, role);
        //this.props.history.replace('/');
        this.props.switchToLogIn();
    }

    render() {
        const controls = Object.keys(this.state.registerForm.controls).map((controlName, key) => {
            const config = this.state.registerForm.controls[controlName];
            return (
                <Input key={key}
                    inputType={config.inputType}
                    type={config.type}
                    name={controlName}
                    changed={this.inputChangedHandler}
                    label={config.label}
                    required={config.required}
                    placeholder={controlName}
                    value={config.value}
                    valid={config.valid}
                    touched={config.touched} 
                    options={config.options}/>
            );
        });

        let error = null;

        if (this.props.error) {
            error = <p className={css.Error}>{this.props.error}</p>
        }

        if (this.state.registerForm.error) {
            error = <p className={css.Error}>{this.state.registerForm.error}</p>
        }

        if (this.props.registrationMessage) {
            if (!this.props.registrationSuccessful) {
                error = <p className={css.Error}>{this.props.registrationMessage}</p>
            }
            else {
                error = <p className={css.Success}>{this.props.registrationMessage}</p>
            }
        }

        let form = <Spinner style={{ marginTop: '2em' }} />

        if (!this.props.loading) {
            form = (
                <Fragment>
                    {controls}
                    {error}
                    <Button  disabled={!this.state.registerForm.formIsValid}>Register</Button>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <form className={css.RegisterForm} onSubmit={this.onSubmitHandler}>
                    {form}
                </form>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        loading: state.auth.loading,
        registrationSuccessful: state.auth.registrationSuccessful,
        registrationMessage: state.auth.registrationMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRegistration: (firstName, lastName, email, password, role) => dispatch(actions.register(firstName, lastName, email, password, role))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterForm));