import * as actionTypes from '../../actions/users/actionTypes'
import { inputChanged } from "../../../utility/forms/inputChanged";
import produce from 'immer'


const initialState = {
    form: {
      controls: {
        username: {
          label: "Username",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        emailSchool: {
          label: "Email school",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        },
        emailPrivate: {
          label: "Email private",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        state: {
          label: "Account state",
          value: null,
          inputType: "select",
          type: "text",
          validators: {},
          options: [
            {
              text: "Active",
              value: "Active",
            },
            {
              text: "Inactive",
              value: "Inactive",
            },
          ],
          valid: false,
          touched: false,
          disabled: true,
        },
        social_id: {
          label: "Social ID",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        },
        signup_date: {
          label: "Signup date",
          value: null,
          inputType: "input",
          type: "date",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        },
        last_seen_date: {
          label: "Last seen",
          value: null,
          inputType: "input",
          type: "date",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        },
        firstName: {
          label: "First name",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        lastName: {
          label: "Last name",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        mobile: {
          label: "Mobile",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        address: {
          label: "Address",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        postalCode: {
          label: "Postal code",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        nationality: {
          label: "Nationality",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
        },
        gender: {
          label: "Gender",
          value: null,
          inputType: "select",
          validators: {},
          valid: false,
          touched: false,
          options: [
            {
              text: "Male",
              value: "Male",
            },
            {
              text: "Female",
              value: "Female",
            },
          ],
        },
        pcn: {
          label: "PCN",
          value: null,
          inputType: "input",
          type: "text",
          validators: {},
          valid: false,
          touched: false,
          disabled: true,
        }
      },
      isValid: false,
      error: null,
    },
    loading: false,
    error: null,
    users: null,
    deleted: false,
    edited: false,
    added: false,
    id: null,
    user: null
  };

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
          //CHANGE INPUT
          case actionTypes.INPUT_VALUE_CHANGED_USER:
            // update the input value in the state
            inputChanged(draft, action.name, action.value);
            break;
          //GET LIST OF USERS
          case actionTypes.GET_USERS_START:
              draft.loading = true;
              draft.error = null;
              break;
  
          case actionTypes.GET_USERS_FAIL:
              draft.loading = false;
              draft.error = action.error;
              break;

          case actionTypes.GET_USERS_SUCCESS:
              draft.loading = false;
              draft.users = action.users
              break;
          //GET ONE USER
          case actionTypes.GET_USER_START:
            draft.loading = true;
            draft.error = null;
            break;

          case actionTypes.GET_USER_FAIL:
            draft.loading = false;
            draft.error = action.error;
            break;

          case actionTypes.GET_USER_SUCCESS:
            draft.loading = false;
            draft.user = action.user
            if(action.info){
              Object.keys(action.info).map((controlName) => {
                draft.form.controls[controlName].value = action.info[controlName]
                ? action.info[controlName]
                  : '';
              });
              draft.form.isValid = true;
            }
            break;

          //DELETE
          case actionTypes.DELETE_USER_START:
              draft.loading = true;
              draft.error = null;
              draft.deleted = false;
              break;
      
          case actionTypes.DELETE_USER_FAIL:
              draft.loading = false;
              draft.error = action.error;
              break;
      
          case actionTypes.DELETE_USER_SUCCESS:
              draft.deleted = true;
              draft.loading = false;
              draft.id = draft.id;
              break;
          default:

          //UPDATE
          case actionTypes.UPDATE_USER_START:
              draft.loading = true;
              draft.error = null;
              draft.edited = false;
              break;
          case actionTypes.UPDATE_USER_FAIL:
              draft.loading = false;
              draft.error = action.error;
              break;
          case actionTypes.UPDATE_USER_SUCCESS:
            draft.loading = false;
            draft.edited = true;
              // populate state form controls
              if(action.info){
                Object.keys(action.info).forEach((controlName) => {
                  draft.form.controls[controlName].value = action.info[controlName]
                  ? action.info[controlName]
                  : null;
                });
              }
              break;

            //CREATE
          case actionTypes.ADD_USER_START:
              draft.loading = true;
              draft.error = null;
              draft.added = false;
              break;
      
          case actionTypes.ADD_USER_FAIL:
              draft.loading = false;
              draft.error = action.error;
              break;
      
          case actionTypes.ADD_USER_SUCCESS:
              draft.loading = false;
              draft.id = action.id;
              draft.data = action.data;
              draft.added = true;
              break;
        }
    })

 
export default reducer;