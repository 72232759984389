import React, { Fragment } from 'react';
import css from './Input.module.scss'

const input = (props) => {
    let inputElement = null    

    // Depending on the type of the input, create a certain type of input element
    switch (props.inputType) {
        case 'input':
            inputElement = (
                <input className={css.Input}
                    type={props.type}
                    name={props.name}
                    onChange={(event) => props.changed(props.name, event.target.value)}
                    placeholder={props.placeholder}
                    required={props.required}
                    value={props.value ? props.value : ""}
                    disabled={props.disabled}
                />
            )
            break
        case 'select':

            inputElement = (<select className={css.Select}
                name={props.name}
                onChange={(event) => props.changed(props.name, event.target.value)}
                placeholder={props.placeholder}
                required={props.required}
                value={props.value ? props.value : ""}
                disabled={props.disabled}>

                {props.options.map((option, key) => (
                    <option key={key}
                        value={option.value}>
                        {option.text}
                    </option>
                ))}
            </select>
            )
            break
        case 'textarea':
            inputElement= (<textarea className={css.Textarea}
                name={props.name}
                onChange={(event) => props.changed(props.name, event.target.value)}
                placeholder={props.placeholder}
                required={props.required}
                rows={props.rows}
                value={props.value ? props.value : ""}
                disabled={props.disabled}>

                </textarea>

            )
            break
        default:
            inputElement = null
            break
    }

    // Set the style of the label depending on the state of the input element
    let styleClasses = [css.Label]

    if (!props.touched) styleClasses.push(css.Untouched)
    else if (!props.valid) styleClasses.push(css.Invalid)
    else if (props.valid) styleClasses.push(css.Valid)

    if (props.label) {
        inputElement = (
            <div className={css.Wrapper}>
                <label htmlFor={props.name} className={styleClasses.join(' ')}>
                    {props.label}
                </label>
                {inputElement}
            </div>

        )
    }

    return (
        <Fragment>
            {inputElement}
        </Fragment>
    );
}

export default input;