import * as actionTypes from "../../actions/annoncements/ActionTypes";
import produce from "immer";

const initialState = {
  announcements: null,
  error: null,
  loading: true,
  added: false,
  userSpecific: false,
  deleted: false,
  responceData: null
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      //GET ALL ANNOUNCEMENTs
      case actionTypes.GET_ANNOUNCEMENTS_START:
        draft.loading = true;
        draft.error = null;
        draft.added = false;
        draft.userSpecific = false;
        draft.deleted = false;
        break;
      case actionTypes.GET_ANNOUNCEMENTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.userSpecific = false;
        break;
      case actionTypes.GET_ANNOUNCEMENTS_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.announcements = action.announcements;
        draft.userSpecific = false;
        break;
      //CREATE
      case actionTypes.ADD_ANNOUNCEMENT_START:
        draft.loading = true;
        draft.error = null;
        draft.added = false;
        draft.deleted = false;
        break;
      case actionTypes.ADD_ANNOUNCEMENT_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.added = false;
        break;
      case actionTypes.ADD_ANNOUNCEMENT_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.responceData = action.responceData;
        draft.added = true;
        break;
      //DELETE
      case actionTypes.DELETE_ANNOUNCEMENT_START:
        draft.loading = true;
        draft.added = false;
        draft.deleted = false;
        draft.error = null;
        break;
      case actionTypes.DELETE_ANNOUNCEMENT_FAIL:
        draft.loading = false;
        draft.deleted = false;
        draft.error = action.error;
        break;
      case actionTypes.DELETE_ANNOUNCEMENT_SUCCESS:
          draft.loading = false;
          draft.error = null;
          draft.deleted = true;
          draft.responceData = action.responceData;
        break;
        //GET USER SPECIFIC ANNOUNCEMNTS
      case actionTypes.FETCH_USER_ANNOUNCEMENTS_START:
        draft.loading = true;
        draft.error = null;
        draft.userSpecific = false;
        draft.deleted = false;
        draft.added = false;
        break;
      case actionTypes.FETCH_USER_ANNOUNCEMENTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.userSpecific = false;
        draft.added = false;
        break;
      case actionTypes.FETCH_USER_ANNOUNCEMENTS_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.announcements = action.announcements;
        draft.userSpecific = true;
        break;

      default:
    }
  });

export default reducer;
