import React from 'react';
import css from './Toolbar.module.scss';

// import routes
import { routes } from '../../../../routes/landingPage'

// import components
import NavigationItem from './NavigationItem/NavigationItem';

const toolbar = (props) => {    

    let navigationItems;

    if (routes.length > 0) {
        navigationItems = routes.map((item, key) => (
            <NavigationItem key={key}
                link={item.link}
                text={item.text}
                exact={item.exact} />
        ));
    }

    return (
            <div className={css.Toolbar}>
                <nav>
                    <ul className={css.NavigationItems}>
                        {navigationItems}
                    </ul>
                </nav>
            </div>
    );
}

export default toolbar;