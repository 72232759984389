export const GET_GRADINGMODELS_START = 'GET_GRADINGMODELSES_START';
export const GET_GRADINGMODELS_SUCCESS = 'GET_GRADINGMODELSES_SUCCESS';
export const GET_GRADINGMODELS_FAIL = 'GET_GRADINGMODELSES_FAIL';

export const ADD_GRADINGMODEL_START = 'ADD_GRADINGMODELS_START';
export const ADD_GRADINGMODEL_SUCCESS = 'ADD_GRADINGMODELS_SUCCESS';
export const ADD_GRADINGMODEL_FAIL = 'ADD_GRADINGMODELS_FAIL';

export const DELETE_GRADINGMODEL_START = 'DELETE_GRADINGMODELS_START';
export const DELETE_GRADINGMODEL_SUCCESS = 'DELETE_GRADINGMODELS_SUCCESS';
export const DELETE_GRADINGMODEL_FAIL = 'DELETE_GRADINGMODELS_FAIL';

export const GET_GRADINGMODEL_START = 'GET_GRADINGMODELS_START';
export const GET_GRADINGMODEL_SUCCESS = 'GET_GRADINGMODELS_SUCCESS';
export const GET_GRADINGMODEL_FAIL = 'GET_GRADINGMODELS_FAIL';

export const PUT_GRADINGMODEL_START = 'PUT_GRADINGMODELS_START';
export const PUT_GRADINGMODEL_SUCCESS = 'PUT_GRADINGMODELS_SUCCESS';
export const PUT_GRADINGMODEL_FAIL = 'PUT_GRADINGMODELS_FAIL';

export const INPUT_VALUE_CHANGED_GRADINGMODELS_CREATE = 'INPUT_VALUE_CHANGED_GRADINGMODELS_CREATE';
export const INPUT_VALUE_CHANGED_GRADINGMODELS_UPDATE = 'INPUT_VALUE_CHANGED_GRADINGMODELS_UPDATE';