import * as actionTypes from './actionTypes';
import axios from '../../../../axios/localhost';


// Check current  state
// export const checkSubmitTimeout = (expirationTime) => {
//     return dispatch => {
//         setTimeout(() => {
//             dispatch(logout());
//         }, expirationTime * 1000);
//     }
// }

// Submit
export const submitStart = () => {
    return {
        type: actionTypes.SUBMIT_START
    }
}

export const submitSuccess = (submitData) => {

    return {
        type: actionTypes.SUBMIT_SUCCESS,
        email: submitData.email,
        title: submitData.title,
        content: submitData.content
    }
}

export const submitFail = (error) => {
    return {
        type: actionTypes.SUBMIT_FAIL,
        error: error
    }
}

export const submit = (email, title, content) => {
    return dispatch => {
        dispatch(submitStart())

        let contactData = {
            email: email,
            title: title,
            content: content
        }

        axios.post('/contact', contactData)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.duration * 1000);


                dispatch(submitSuccess({
                    ...response.data
                }));
                // dispatch(checkSubmitTimeout(response.data.duration));
            })
            .catch(error => {

                dispatch(submitFail(error.response.data.message));
            })
    }

}