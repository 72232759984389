import React from 'react';
import css from './Button.module.scss'

const button = (props) => {
    return ( 
        <button onClick={props.clicked} className={css.Button} style={props.style} disabled={props.disabled}>
            {props.children}
        </button>
     );
}
 
export default button;