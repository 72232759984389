export const DELETE_STUDY_PATHS = 'DELETE_STUDY_PATHS'
export const DELETE_STUDY_PATHS_START = 'DELETE_STUDY_PATHS_START'
export const DELETE_STUDY_PATHS_FAIL = 'DELETE_STUDY_PATHS_FAIL'
export const DELETE_STUDY_PATHS_SUCCESS = 'DELETE_STUDY_PATHS_SUCCESS'

export const GET_STUDY_PATHS = 'GET_STUDY_PATHS'
export const GET_STUDY_PATHS_START = 'GET_STUDY_PATHS_START'
export const GET_STUDY_PATHS_FAIL = 'GET_STUDY_PATHS_FAIL'
export const GET_STUDY_PATHS_SUCCESS = 'GET_STUDY_PATHS_SUCCESS'

export const POST_STUDY_PATHS = 'POST_STUDY_PATHS'
export const POST_STUDY_PATHS_START = 'POST_STUDY_PATHS_START'
export const POST_STUDY_PATHS_FAIL = 'POST_STUDY_PATHS_FAIL'
export const POST_STUDY_PATHS_SUCCESS = 'POST_STUDY_PATHS_SUCCESS'

export const PUT_STUDY_PATHS = 'PUT_STUDY_PATHS'
export const PUT_STUDY_PATHS_START = 'PUT_STUDY_PATHS_START'
export const PUT_STUDY_PATHS_FAIL = 'PUT_STUDY_PATHS_FAIL'
export const PUT_STUDY_PATHS_SUCCESS = 'PUT_STUDY_PATHS_SUCCESS'

export const INPUT_VALUE_CHANGED_STUDY_PATHS_CREATE = 'INPUT_VALUE_CHANGED_STUDY_PATHS_CREATE'
export const INPUT_VALUE_CHANGED_STUDY_PATHS_UPDATE = 'INPUT_VALUE_CHANGED_STUDY_PATHS_UPDATE'