import {validateInput} from './validateInput'

export const inputChanged = (draft, inputName, inputValue) => {
    const control = draft.form.controls[inputName]
    control.value = inputValue
    control.valid = validateInput(control.validators, inputValue)
    control.touched = true

    let formIsValid = true

    Object.values(draft.form.controls).forEach((config) => {
        formIsValid = formIsValid && config.valid
    })

    draft.form.isValid = formIsValid
}