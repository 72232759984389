import React from 'react';
import css from './ModuleContent.module.scss'
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { functionForGrabbingAllPluginDecorators } from '../../../../../../utility/functionForGrabbingAllPluginDecorators';

const ModuleContent = (props) =>  {
    let body = null;
    let contentState = null;
    let editorState = null;
    if(props.data){
        contentState = convertFromRaw(JSON.parse(props.data));
        let decorator = functionForGrabbingAllPluginDecorators();
        editorState = EditorState.createWithContent(contentState, decorator);
        body = (
            <div>
                <Editor editorState={editorState} readOnly={true} />
            </div>
        )
    }
    return ( 
        <div className={css.ModuleContent}>
            <h1>ModuleContent</h1>
            {body}
        </div>
    );
}
export default ModuleContent;