import React, { Component, Fragment } from "react";
import css from "./ForgottenPasswordForm.module.scss";

// import components
import Input from "../../../../components/forms/landing-page/Input/Input";
import Spinner from "../../../../components/ui/spinners/landing-page/Spinner/Spinner";
import Button from "../../../../components/ui/buttons/landing-page/Button/Button";

// imports from packages
import produce from "immer";
import { validateInput } from "../../../../utility/forms/validateInput";
import { FiMail } from "react-icons/all";

// redux store
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/auth";

class LoginForm extends Component {
  state = {
    emailSubmited: false,
    loginForm: {
      controls: {
        email: {
          label: "Email",
          inputType: "input",
          type: "email",
          value: "",
          validators: {
            required: true,
            isEmail: true,
          },
          valid: false,
          touched: false,
          label: <FiMail />,
        },
      },
      formIsValid: false,
    },
    verificationCode: {
        controls:{
            code: {
                label: "VC",
                inputType: "input",
                type: "input",
                value: "",
                validators: {
                  required: true
                },
                valid: false,
                touched: false,
            },
        },
        formIsValid: false,
    }
  };

  inputChangedHandler = (controlName, value) => {
    this.setState(
      produce((draft) => {
        let control = null;
        if(!draft.verificationCode.controls[controlName]){
            control  = draft.loginForm.controls[controlName];
        }
        else{
            control = draft.verificationCode.controls[controlName];
        }
        control.value = value;
        control.valid = validateInput(control.validators, value);
        control.touched = true;

        let formIsValid = true;

        Object.values(draft.loginForm.controls).forEach((config) => {
          formIsValid = formIsValid && config.valid;
        });

        draft.loginForm.formIsValid = formIsValid;
      })
    );
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    this.setState({emailSubmited: true});
    // const controls = this.state.loginForm.controls;
    // const email = controls.email.value;

    // this.props.onLogin(email);
  };

  render() {
    let error = null;

    if (this.props.error) {
      error = <p className={css.Error}>{this.props.error}</p>;
    }

    let form = <Spinner style={{ marginTop: "2em" }} />;

    if (!this.props.loading && !this.state.emailSubmited) {
      form = (
        <Fragment>
          <Input
            inputType={this.state.loginForm.controls.email.inputType}
            type={this.state.loginForm.controls.email.type}
            name="email"
            changed={this.inputChangedHandler}
            label={this.state.loginForm.controls.email.label}
            required={this.state.loginForm.controls.email.required}
            placeholder="Email"
            value={this.state.loginForm.controls.email.value}
            valid={this.state.loginForm.controls.email.valid}
            touched={this.state.loginForm.controls.email.touched}
            />
          {error}
          <Button disabled={!this.state.loginForm.formIsValid}>Send verification code</Button>
          <p className={css.ForgottenPassword} onClick={this.props.onForgottenPasswordHandler}>
            Back to login?
          </p>
        </Fragment>
      );
    }
    else if (!this.props.loading && this.state.emailSubmited){
        form = <Fragment>
        <Input
          inputType={this.state.verificationCode.controls.code.inputType}
          type={this.state.verificationCode.controls.code.type}
          name="code"
          changed={this.inputChangedHandler}
          label={this.state.verificationCode.controls.code.label}
          required={this.state.verificationCode.controls.code.required}
          placeholder="Verification Code"
          value={this.state.verificationCode.controls.code.value}
          valid={this.state.verificationCode.controls.code.valid}
          touched={this.state.verificationCode.controls.code.touched}
          />
        {error}
        <Button disabled={!this.state.loginForm.formIsValid}>Verify</Button>
        <p className={css.ForgottenPassword} onClick={this.props.onForgottenPasswordHandler}>
          Back to login?
        </p>
      </Fragment>
    }

    return (
      <Fragment>
        <form className={css.LoginForm} onSubmit={this.onSubmitHandler}>
          {form}
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    loading: state.auth.loading,
    registrationSuccessful: state.auth.registrationSuccessful,
    registrationMessage: state.auth.registrationMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //onLogin: (email, password) => dispatch(actions.login(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
