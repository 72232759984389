import React from 'react';
import css from './ModuleTeachers.module.scss'

import TeacherAvatar from '../../../../../../assets/images/Teacher.svg' 

const moduleTeachers = (props) => {
    const teacher =  Object.values(props.users).findIndex(user => user.id === props.ownerID);
    const teacherFigures = 
        <figure>
            <img src={TeacherAvatar} alt={teacher.firstName} />
            <figcaption>
                <h3>{teacher.firstName} {teacher.lastName}</h3>
                <p>{teacher.rolesString}</p>
            </figcaption>
        </figure>
   

    return ( 
        <div className={css.ModuleTeachers}>
            {teacherFigures}
        </div>
     );
}
 
export default moduleTeachers;