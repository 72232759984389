export const feedbackMockData = [ // TODO: this data should be received from the database
    {   
     
        id: 0, 
        classID: 1,
        teacherID: 14,
        studentID: 13,
        teacherFullName: "Asd asd",
        moduleCode: "ASD",
        moduleName: "asd asd asd asd",
        date: '12.01.2021',
        subject: "asd asd asd asd",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
    },
    {   
        id: 2, 
        classID: 1,
        teacherID: 14,
        studentID: 13,
        teacherFullName: "Asd asd",
        moduleCode: "ASD",
        moduleName: "asd asd asd asd",
        date: '12.01.2021',
        subject: "asd asd asd asd",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
    },
    {   
        id: 3, 
        classID: 1,
        teacherID: 14,
        studentID: 13,
        teacherFullName: "Asd asd",
        moduleCode: "ASD",
        moduleName: "asd asd asd asd",
        date: '12.01.2021',
        subject: "asd asd asd asd",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
    },
    {  
        id: 4, 
        classID: 1,
        teacherID: 14,
        studentID: 10,
        teacherFullName: "Asd asd",
        moduleCode: "ASD",
        moduleName: "asd asd asd asd",
        date: '12.01.2021',
        subject: "asd asd asd asd",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
    },
    {   
        id: 5, 
        classID: 1,
        teacherID: 14,
        studentID: '14',
        teacherFullName: "Asd asd",
        moduleCode: "ASD",
        moduleName: "asd asd asd asd",
        date: '12.01.2021',
        subject: "asd asd asd asd",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
    },
    {   
        id: 6, 
        classID: 1,
        teacherID: 14,
        studentID: 7,
        teacherFullName: "Asd asd",
        moduleCode: "ASD",
        moduleName: "asd asd asd asd",
        date: '12.01.2021',
        subject: "asd asd asd asd",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"

    },
    {   
        id: 7, 
        classID: 1,
        teacherID: 14,
        studentID: 14,
        teacherFullName: "Asd asd",
        moduleCode: "ASD",
        moduleName: "asd asd asd asd",
        date: '12.01.2021',
        subject: "asd asd asd asd",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"

    },
    {   
        id: 8, 
        classID: 1,
        teacherID: 14,
        studentID: 14,
        teacherFullName: "Teacher Teach",
        moduleCode: "PDC-43",
        moduleName: "PDC-43",
        date: '12.01.2021',
        subject: "Needs more attendance",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"

    },
    {   
        id: 9, 
        classID: 1,
        teacherID: 14,
        studentID: 14,
        teacherFullName: "Teacher Teach",
        moduleCode: "PFDS",
        moduleName: "PDFDSFSDKLJF",
        date: '12.01.2021',
        subject: "Overall happy with your work",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"

    },
    {   
        id: 10, 
        classID: 1,
        teacherID: 14,
        studentID: 14,
        teacherFullName: "Teacher Teach",
        moduleCode: "DS-A",
        moduleName: "Data Science A",
        date: '12.01.2021',
        subject: "Improve Portfolio",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"

    },
    {   
        id: 11, 
        classID: 1,
        teacherID: 14,
        studentID: 14,
        teacherFullName: "Teacher Teach",
        moduleCode: "UX-3",
        moduleName: "User Experiance Semester 3",
        date: '12.01.2021',
        subject: "Great Designs",
        feedback: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"

    }
]