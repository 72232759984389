import React from 'react';
import css from './GeneralInfo.module.scss'

const generalInfo = (props) => {
    console.log(props.name);
    return (
        <div className={css.GeneralInfo}>
            <h1>{props.code} - {props.name}</h1>
            <div>
                <div className={css.InfoCard}>
                    <h2>Module code</h2>
                    <p>{props.code}</p>
                </div>
                <div className={css.InfoCard}>
                    <h2>Module name</h2>
                    <p>{props.name}</p>
                </div>
                <div className={css.InfoCard}>
                    <h2>Credits</h2>
                    <p>{props.credits}</p>
                </div>
            </div>
        </div>
    );
}

export default generalInfo;