import * as actionTypes from '../../actions/institute-stats/actionTypes'
import produce from 'immer'

const initialState = {
    instituteStats : null,
    tempData: null,
    error: null,
    loading: false
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.GET_INSTITUTE_STATS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_INSTITUTE_STATS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_INSTITUTE_STATS_SUCCESS):
                draft.loading = false
                draft.tempData = action.tempData
                // populate state form controls
                draft.instituteStats = action.instituteStats
                break
            
            default:
        }
    })

export default reducer
