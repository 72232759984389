import React from 'react';
import css from './Footer.module.scss';

import Logo from '../../../../assets/images/AIM-logo.png'


const footer = (props) => {

    return ( 
        <div  style={props.style}>
            <div className={css.Footer}>
            <div className={css.Body}>

            <footer className={css.footer}>
            <div className={css.footer__addr}>
                <img className={css.footer__logo} src={Logo}/>
                    

                
                <address>
                &nbsp;5642GL Eindhoven, Netherlands.<br/>
                &nbsp;Tell: 0031 648001240
                    
                info@proshout.nl
                </address>
                <p>
                    <span>Privacy Policy | </span><span>   Terms of Use </span>
                </p>
            </div>
            


            
            </footer>






                {props.children}
            </div>       
        </div>
        <div className={css.footerButtom}>
         <p>&copy; Proshout 2019. All Right Reserved.</p>
        </div>
        </div>
    );
}
 
export default footer;