import React from "react";

// imports from assets
import Student from "../assets/images/profile_square.jpg";
import FeedbackIcon from '@material-ui/icons/Feedback';
import GradeIcon from '@material-ui/icons/Grade';
// imports from packages
// import { GiSpeedometer, FiGrid, IoMdLink, IoIosNotifications, FiLogOut, IoMdHelpCircleOutline, IoMdInformationCircleOutline, IoMdPeople} from 'react-icons/all';
import {
  GiSpeedometer,
  IoMdInformationCircleOutline,
  FiGrid,
  FiLogOut,
  IoMdHelpCircleOutline,
  IoMdLink,
  IoIosNotifications,
  IoMdPeople,
  FaSchool,
  GiWhiteBook,
  
  IoIosCalendar,
  IoIosMail,
  MdBugReport
} from "react-icons/all";

// import hocs
import WaitingComponent from "../hoc/WaitingComponent/WaitingComponent";

// lazy load views
const StudentDash = React.lazy(() =>
  import("../views/main-application/student/StudentDash/StudentDash")
);
const StudentProfilePage = React.lazy(() =>
  import("../components/ProfilePage/ProfilePage")
);
const StudentFHICTInfo = React.lazy(() =>
  import("../views/main-application/student/FHICTInfo/FHICTInfo")
);
const StudentClasses = React.lazy(() =>
  import("../views/main-application/student/Classes/ClassesPage")
);
const Modules = React.lazy(() =>
  import("../views/main-application/student/Modules/Modules")
);
const Calendar = React.lazy(() =>
  import("../views/main-application/student/Calendar/Calendar")
);

const Messages = React.lazy(() =>
  import("../views/main-application/student/Messages/Messages")
);
const Feedback = React.lazy(() =>
  import("../views/main-application/student/TeacherFeedback/TeacherFeedback")
);
const Grades = React.lazy(() =>
  import("../views/main-application/student/Grades/Grades")
);
const Announcements = React.lazy(() =>
  import("../views/main-application/student/Announcements/Announcements")
);
const Help = React.lazy(() =>
  import("../views/main-application/student/Help/Help")
);
const UsefulLinks = React.lazy(() =>
  import("../views/main-application/student/UsefulLinks/UsefulLinks")
);
const ReportProblemPage = React.lazy(() => import("../components/report-problem/reportProblem"));

const routes = [
  // Properties/Attributes
  // view: <component> lazy load
  // last: appends item to bottom left of left sidebar
  {
    text: localStorage.getItem("firstName")
      ? `${localStorage.getItem("firstName") }`
      : "Profile Page",
    link: localStorage.getItem("firstName")
      ? `/${localStorage
          .getItem("firstName")
          .toLowerCase()}`
      : "/profilepage",
    exact: true,
    icon: <img src={Student} alt="Student" />,
    view: WaitingComponent(StudentProfilePage),
  },
  {
    text: "Dashboard",
    link: "/",
    exact: true,
    icon: <GiSpeedometer />,
    view: WaitingComponent(StudentDash),
  },
  {
    text: "My Classes",
    link: "/classes",
    icon: <IoMdPeople />,
    view: WaitingComponent(StudentClasses),
  },
 
  {
    text: "My Institute",
    link: "/fhict-info",
    icon: <FaSchool />,
    view: WaitingComponent(StudentFHICTInfo),
  },

  {
    text: "Announcements",
    link: "/announcements",
    icon: <IoIosNotifications />,
    view: WaitingComponent(Announcements),
  },
  {
    text: "Calendar",
    link: "/calendar",
    icon: <IoIosCalendar />,
    view: WaitingComponent(Calendar),
  },
  {
    text: "Inbox",
    link: "/messages",
    icon: <IoIosMail />,
    view: WaitingComponent(Messages),
  },
  {
    text: "My Feedback",
    link: "/feedback",
    icon: <FeedbackIcon />,
    view: WaitingComponent(Feedback),
  },
  {
    text: "My Grades",
    link: "/grades",
    icon: <GradeIcon />,
    view: WaitingComponent(Grades),
  },
  {
    text: "Useful Links",
    link: "/usefullinks",
    icon: <IoMdLink />,
    view: WaitingComponent(UsefulLinks),
  },
  {
    text: "Help",
    link: "/help",
    icon: <IoMdHelpCircleOutline />,
    view: WaitingComponent(Help),
  },
 
  {
    link: '/applicationFeedback',
    text: 'Report an Issue',
    icon: <MdBugReport />,
    view: WaitingComponent(ReportProblemPage),
   
},
{
  text: "Log Out",
  icon: <FiLogOut />,
  // Append item to bottom most possition of left sidebar
  last: true
},
];

export default routes;
