import React, { useState, useEffect,Fragment} from "react";
import { withRouter } from 'react-router-dom';
import css from './Module.module.scss'
import axios from "../../../../../axios/localhost";
import { connect } from 'react-redux'
import Card from '../../../../../components/ui/cards/Card/Card'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import GeneralInfo from './GeneralInfo/GeneralInfo'
import ModuleTeachers from './ModuleTeachers/ModuleTeachers'
import Requirements from './Requirements/Requirements'
import LearningOutcomes from './LearningOutcomes/LearningOutcomes'
import ModuleContent from './ModuleContent/ModuleContent'
import Assessment from './Assessment/Assessment'
import TimePlanning from './TimePlanning/TimePlanning'
import {getUsers} from '../../../../../store/actions/users'
import Row from '../../../../../components/ui/grid/Row/Row'
import Col from '../../../../../components/ui/grid/Col/Col'

const  Module  = (props)=> {
    
    const [ModuleState, setModuleState] = useState({});
    useEffect(() => {
        props.onGetUsers();  
        axios.get(`/modules/${props.match.params.code}`).then((response) => {
            setModuleState({ "module":response.data });
            
      });
      }, [props.match.params.code])


       
        let content = "LOADING..."

        if (ModuleState.module && props.users !== null) {
         
        content = (
            <Fragment>
                <Row marginBottom>
                    <Col>
                        <GeneralInfo code={ModuleState.module.code} name={ModuleState.module.name} credits={ModuleState.module.credits} />
                        <Requirements data = {ModuleState.module.requirements} />
                    </Col>
                    <Col>
                        <ModuleTeachers users = {props.users} ownerID ={ModuleState.module.ownerID}  />
                    </Col>
                </Row>
                <Row marginBottom>
                    <LearningOutcomes data={ModuleState.module.learningOutcome} />
                </Row>
                <Row marginBottom>
                    <ModuleContent data={ModuleState.module.content}/>
                </Row>
                <Row marginBottom>
                    <Assessment data={ModuleState.module.assessment} />
                </Row>
                <Row marginBottom>
                    <TimePlanning data={ModuleState.module.planning}/>
                </Row>
                <Box m={2}> <Button onClick={props.history.goBack}  variant="contained" type="button" >Back</Button></Box>
            </Fragment>
    

        )}

        return (
            <div className={css.Content}>
                <Card>
                    {content}
                </Card>
            </div>
        );
    }


const mapStateToProps = (state) => {
    return {
        users: state.users.users,
    };
};
    
    const mapDispatchToProps = (dispatch) => {
    return {
        onGetUsers: () => dispatch(getUsers()),
    };
};
export default  connect(mapStateToProps, mapDispatchToProps)(Module);