import * as actionTypes from '../../actions/modules/actionTypes'
import produce from 'immer'
import { inputChanged } from '../../../utility/forms/inputChanged'
const initialState = {
    modules: null,
    form: {
        controls: {     
            name: {
                label: "Name",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Name',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
            code: {
                label: "Code",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Code',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
            credits: {
                label: "credits",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'credits',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
            description: {
                label: "Description",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Description',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
            requirements: {
                label: "requirements",
                value: [],
                inputType: 'input',
                type: 'select',
                placeholder: 'requirements',
                validators: {
                },
                valid: false,
                touched: false,
                required: false
            },
            ownerID: {
                label: "ownerID",
                value: null,
                inputType: 'input',
                type: 'selectbox',
                placeholder: 'OwnerID',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
            learningOutcome: {
                label: "Learning Outcomes",
                value: null,
                inputType: 'input',
                type: 'textarea',
                placeholder: 'Learning Outcomes',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
            content: {
                label: "Content",
                value: null,
                inputType: 'input',
                type: 'textarea',
                placeholder: 'Learning Outcomes',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
            assessment: {
                label: "Assessment",
                value: null,
                inputType: 'input',
                type: 'textarea',
                placeholder: 'Assessment',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
            planning: {
                label: "Planning",
                value: null,
                inputType: 'input',
                type: 'textarea',
                placeholder: 'Planning',
                validators: {
                },
                valid: false,
                touched: false,
                required: true
            },
        },
        isValid: false,
        error: null
    },
    error: null,
    loading: false,
    deleting: false,
    editing: false,
    adding: false
}

const reducer = (state = initialState, action) => 
produce(state, draft => {
    switch(action.type) {
        
        case(actionTypes.INPUT_VALUE_CHANGED_MODULES_UPDATE):
            // update the input value in the state
            inputChanged(draft, action.name, action.value)
            // also update the current value of the working experience in the array of working experience
            const updatedmodules = draft.modules.find(exp => exp.id === action.id)
            updatedmodules[action.name] = action.value
            draft.modules.forEach(temp => {
                if (temp.id === action.id) temp = updatedmodules
            } )
            break

        case(actionTypes.INPUT_VALUE_CHANGED_MODULES_CREATE):
            inputChanged(draft, action.name, action.value)
            break

        case actionTypes.GET_MODULES_START:
            draft.loading = true;
            draft.error = null;
            draft.editing = false;

            break;
        case actionTypes.GET_MODULES_FAIL:
            draft.loading = false;
            draft.error = action.error;
            break;
        case actionTypes.GET_MODULES_SUCCESS:
            draft.loading = false;
            draft.modules = action.modules
            break;
        default:
        
        case(actionTypes.DELETE_MODULES_START):
            draft.loading = true;
            draft.error = null;
            break
        case(actionTypes.DELETE_MODULES_FAIL):
            draft.loading = false;
            draft.error = action.error;
            break
        case(actionTypes.DELETE_MODULES_SUCCESS):
            draft.deleting = true;
            draft.loading = false;
            if(draft.modules){
                draft.modules = draft.modules.filter(exp => exp.id !== action.id)
            }
            break

        case(actionTypes.POST_MODULES_START):
            draft.loading = true;
            draft.error = null;
            break
        case(actionTypes.POST_MODULES_FAIL):
            draft.loading = false;
            draft.error = action.error;
            break
        case(actionTypes.POST_MODULES_SUCCESS):
            draft.adding = true;
            draft.loading = false;
            if(action.data){
                draft.modules.push(action.data)
            }
            if(draft.form){
                Object.keys(draft.form.controls).forEach(controlName => {
                    draft.form.controls[controlName].value=null
                })
            }
            break

        case(actionTypes.PUT_MODULES_START):
            draft.editing = true
            draft.loading = true;
            draft.error = null;
            break
        case(actionTypes.PUT_MODULES_FAIL):
            draft.loading = false;
            draft.error = action.error;
            break
        case(actionTypes.PUT_MODULES_SUCCESS):
            draft.loading = false;
            draft.editing = true
            if(draft.form){
                Object.keys(draft.form.controls).forEach(controlName => {
                    draft.form.controls[controlName].value = null
                })
            }
            break

        //GET SPECIFIC
        case actionTypes.GET_MODULE_START:
            draft.loading = true;
            draft.error = null;
            break;
        case actionTypes.GET_MODULE_FAIL:
            draft.loading = false;
            draft.error = action.error;
            break;
        case actionTypes.GET_MODULE_SUCCESS:
            draft.loading = false;
            // populate state form controls
            if(action.info){
            Object.keys(action.info).forEach((controlName) => {
                if(controlName!=="id"){
                    draft.form.controls[controlName].value = action.info[controlName]
                    ? action.info[controlName]
                    : '';
                }
            });
            }
            break;
    }
})


 
export default reducer;