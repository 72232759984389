import React,{Fragment} from 'react';
import css from './CardContact.module.scss';

//import component
import ContactForm from '../ContactForm/ContactForm'

const CardContact =(props) => {

    return (
        <div className= {css.CardContact} style={props.style}>
            <div className={css.containerLeft}>
                <h1>
                    Do you want to contact us?
                </h1>
                <h3>
                    Contact us and we'll get back to you within 24 hours.
                </h3>

                <h3>
                    Stellenboschstraat 145, 5642 GL, Eindhoven, Netherlands.
                </h3>
                <h3>
                    +031 648001240
                </h3>
                <h3>
                    info@proshout.nl
                </h3>
            </div>

            <div className={css.containerRight}>
                <h1>Send us an email.</h1>
                <Fragment>
                    <ContactForm/>
                </Fragment>
            </div>

        </div>
    )

}

export default CardContact;