export const validateInput = (rules, value) => {
    let valid = true;

    if (rules.required) {
        valid = value.length > 0 && valid;
    }

    if (rules.minLength) {
        valid = value.length >= rules.minLength && valid;
    }

    if (rules.isEmail) {
        const pattern = /\S+@\S+\.\S+/;
        valid = pattern.test(value) && valid;
    }

    return valid;
}