import React from "react";
import css from "./Card.module.scss";
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
// imports from packages

import { GoGear } from "react-icons/all";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    
    
  } 
}));

const Card = (props) => {
  const classes = useStyles();
  let title = null;
  let classesBody = `${css.Body}`
  let classesCard = `${css.Card}`
  
  //Return which classes should be included based on properties
  if(props.removePadding){
    classesBody+= ` ${css.removePadding}`
  }
  if(props.removeBoxShadow){
    classesCard+= ` ${css.removeBoxShadow}`
  }

  if (props.title) {
    title = (
      <AppBar className={classes.root} color="default"   position="static">
        <h3>{props.title}</h3>
        {props.edit ? (
          <div className={css.Edit} onClick={props.edit}>
            <GoGear />
          </div>
        ) : null}
     </AppBar>
    );
  }

  let footer = null;
  if (props.footer) {
    footer = (
      <header className={css.Footer}>
        <h1>{props.footer}</h1>
      </header>
    );
  }

  return (
    <div className={classesCard} style={props.style}>
      {title}
           
     <Paper square ><div className={classesBody}>{props.children}</div></Paper> 
      {footer}
    </div>
  );
};

export default Card;
