export const DELETE_SKILLS = 'DELETE_SKILLS'
export const DELETE_SKILLS_START = 'DELETE_SKILLS_START'
export const DELETE_SKILLS_FAIL = 'DELETE_SKILLS_FAIL'
export const DELETE_SKILLS_SUCCESS = 'DELETE_SKILLS_SUCCESS'

export const GET_SKILLS = 'GET_SKILLS'
export const GET_SKILLS_START = 'GET_SKILLS_START'
export const GET_SKILLS_FAIL = 'GET_SKILLS_FAIL'
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS'

export const POST_SKILLS = 'POST_SKILLS'
export const POST_SKILLS_START = 'POST_SKILLS_START'
export const POST_SKILLS_FAIL = 'POST_SKILLS_FAIL'
export const POST_SKILLS_SUCCESS = 'POST_SKILLS_SUCCESS'

export const PUT_SKILLS = 'PUT_SKILLS'
export const PUT_SKILLS_START = 'PUT_SKILLS_START'
export const PUT_SKILLS_FAIL = 'PUT_SKILLS_FAIL'
export const PUT_SKILLS_SUCCESS = 'PUT_SKILLS_SUCCESS'

export const INPUT_VALUE_CHANGED_SKILLS = 'INPUT_VALUE_CHANGED_SKILLS'