import * as actionTypes from '../../actions/personal-information/interests/actionTypes'
import produce from 'immer'
import { inputChanged } from '../../../utility/forms/inputChanged'

const initialState = {
    form: {
        controls: {            
            
        },
        isValid: false,
        error: null
    },
    loading: false,
    error: null,
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.INPUT_VALUE_CHANGED_INTERESTS):
                // update the input value in the state
                inputChanged(draft, action.name, action.value)
                break

            case(actionTypes.GET_INTERESTS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_INTERESTS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_INTERESTS_SUCCESS):
                draft.loading = false

                
                Object.keys(action.interests).forEach(interest => {
                    const interestTitle = action.interests[interest].title
                    const interestID = action.interests[interest].id

                    draft.form.controls[interestID] = {
                        id: interestID,
                        name: interestID,
                        label: interestTitle,
                        value: interestTitle,
                        inputType: 'input',
                        type: 'text',
                        validators: {
            
                        },
                        valid: false,
                        touched: false,
                    }
                })
                break
            
            case(actionTypes.DELETE_INTERESTS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.DELETE_INTERESTS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.DELETE_INTERESTS_SUCCESS):
                draft.loading = false
                const controlsWithoutDeletedInterest = Object.values(draft.form.controls).filter(control => control.id !== action.interestID)
                draft.form.controls = {}
                controlsWithoutDeletedInterest.forEach(interest => {
                    draft.form.controls[interest.id] = interest
                })
                break

            case(actionTypes.POST_INTERESTS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.POST_INTERESTS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.POST_INTERESTS_SUCCESS):
                draft.loading = false

                draft.form.controls[action.interestID] = {
                    id: action.interestID,
                    name: action.interestID,
                    label: action.interestTitle,
                    value: action.interestTitle,
                    inputType: 'input',
                    type: 'text',
                    validators: {
        
                    },
                    valid: false,
                    touched: false,
                }                
                break

            case(actionTypes.PUT_INTERESTS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.PUT_INTERESTS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.PUT_INTERESTS_SUCCESS):
                draft.loading = false
                break

            default:
        }
    })

export default reducer
