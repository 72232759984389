import React from 'react';
import css from './CardVideo.module.scss';

// variables
import landing1 from '../../../../assets/images/Student_1.gif'

const cardIntro = (props) => {

    // let footer = null
    // if (props.footer) {
    //     footer = (
    //         <footer className={css.title}>
    //             <h1>{props.footer}</h1>
    //         </footer>
    //     )
    // }

    return (
        <div className={css.CardIntro} style={props.style}>
          <div className={css.wrapper}>
              <h1>Inside App Demo</h1>
            <img  src={landing1} alt="picture of staff and students" ></img>
         </div>
        </div>
    )
}

export default cardIntro;