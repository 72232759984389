import React from 'react';
import MultiDecorator from "draft-js-plugins-editor/lib/Editor/MultiDecorator";
import { CompositeDecorator } from "draft-js";
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import * as R from 'remeda'

function getPluginDecoratorArray() {
    let decorators = [];
    const linkifyPlugin = createLinkifyPlugin({
        target: '_blank',
        component: (params) => <a {...R.omit(params, ['blockKey'])} />
      });
    let plugin = linkifyPlugin;
    let listOfPlugins = [linkifyPlugin];
    for (plugin of listOfPlugins) {
        if (plugin.decorators !== null && plugin.decorators !== undefined) {
            decorators = decorators.concat(plugin.decorators);
        }
    }
    return decorators;
}

export function functionForGrabbingAllPluginDecorators() {
    return new MultiDecorator(
        [new CompositeDecorator(getPluginDecoratorArray())]
    );
}