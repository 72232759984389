import React from 'react';

// imports from assets
import ProfileImg from '../assets/images/TeamLead.svg'

// imports from packages
// import { GiSpeedometer, FiGrid, IoMdLink, IoIosNotifications, FiLogOut, IoMdHelpCircleOutline, IoMdInformationCircleOutline, IoMdPeople} from 'react-icons/all';
import {MdBugReport, MdGrade, MdBeenhere,FaGraduationCap,GiSpeedometer, IoMdInformationCircleOutline, FiGrid, FiLogOut, IoMdPeople, IoIosNotifications, IoMdLink, IoMdHelpCircleOutline,FaSchool} from 'react-icons/all';

// import hocs
import WaitingComponent from '../hoc/WaitingComponent/WaitingComponent'


// lazy load views
const ProfilePage = React.lazy(() => import ('../components/ProfilePage/ProfilePage'))
const UsersPage = React.lazy(() => import ('../views/main-application/team-lead/UsersPage/UsersPage'))
const ClassesPage = React.lazy(() => import ('../views/main-application/team-lead/ClassesPage/ClassesPage'))
const InstituteDash = React.lazy(() => import ('../views/main-application/team-lead/InstituteDash/InstituteDash'))
const Dashboard = React.lazy(() => import ('../views/main-application/team-lead/Dashboard/Dashboard'))
const InstituteInfo = React.lazy(() => import('../views/main-application/team-lead/InstituteInfo/InstituteInfo'))
const Modules = React.lazy(() => import('../views/main-application/team-lead/Modules/Modules'))
const Announcements = React.lazy(() => import('../views/main-application/team-lead/Common/Announcements/Announcements'))
const StudyPaths = React.lazy(() => import('../views/main-application/team-lead/StudyPaths/StudyPaths'))
const UsefulLinks = React.lazy(() => import('../views/main-application/team-lead/UsefulLinks/UsefulLinks'))
const Help = React.lazy(() => import('../views/main-application/team-lead/Help/Help'))
const GradingModel = React.lazy(()=> import('../views/main-application/team-lead/GradingModels/GradingModels'))
const Badges = React.lazy(()=> import('../views/main-application/team-lead/Badges/Badges'))
const ReportProblemPage = React.lazy(() => import("../components/report-problem/reportProblem"));


const routes = [
    {   
       
        text: localStorage.getItem('firstName') ? `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}` : 'Profile Page',
        link: localStorage.getItem('firstName') ? `/${localStorage.getItem('firstName').toLowerCase()}${localStorage.getItem('lastName').toLowerCase()}` : '/profilepage',
        exact: true,
        icon: <img src={ProfileImg} alt="Profile icon" />,
        view: WaitingComponent(ProfilePage)
    },
    {
      
        text: 'Dashboard',
        link: '/',
        exact: true,
        icon: <GiSpeedometer />,
        view: WaitingComponent(Dashboard)
    },
    {
       
        text: 'My Institute',
        link: '/ins-dash',
        icon: <FaSchool />,
        view: WaitingComponent(InstituteDash)
    },
    {
        
        text: 'Users',
        link: '/users',
        icon: <IoMdPeople />,
        view: WaitingComponent(UsersPage)
    },

    {
      
        text: 'Classes',
        link: '/classes',
        icon: <IoMdPeople />,
        view: WaitingComponent(ClassesPage)
    },

    {
       
        text: 'All Modules',
        link: '/modules',
        icon: <FiGrid />,
        view: WaitingComponent(Modules),
        exact: true
    },
    
    {
       
        text: 'Study Paths',
        link: '/studyPaths',
        icon: <FaGraduationCap />,
        view: WaitingComponent(StudyPaths),
       
    },
    {
       
        text: 'Grading Models',
        link: '/gradingModels',
        icon: <MdGrade />,
        view: WaitingComponent(GradingModel),
       
    },
    {
       
        text: 'Badges',
        link: '/badges',
        icon: <MdBeenhere />,
        view: WaitingComponent(Badges),
       
    },
    {
        
        text: 'Institute Info',
        link: '/ins-info',
        icon: <IoMdInformationCircleOutline />,
        view: WaitingComponent(InstituteInfo)
    },
    {
        
        text: 'Announcements',
        link: '/announcements',
        icon: <IoIosNotifications />,
        view: WaitingComponent(Announcements)
    },    
    {
      
        text: 'Useful Links',
        link: '/usefullinks',
        icon: <IoMdLink />,
        view: WaitingComponent(UsefulLinks)
    },
    {
        
        text: 'Help',
        link: '/help',
        icon: <IoMdHelpCircleOutline />,
        view: WaitingComponent(Help)
    },
 
    {
        link: '/applicationFeedback',
        text: 'Report an Issue',
        icon: <MdBugReport />,
        view: WaitingComponent(ReportProblemPage),
       
    },
    {
        text: 'Log Out',
        icon: <FiLogOut />,
        // Append item to bottom most possition of left sidebar
        last: true
    },
]

export default routes