import React from 'react';
import css from './Assessment.module.scss'
import { functionForGrabbingAllPluginDecorators } from '../../../../../../utility/functionForGrabbingAllPluginDecorators';
import { Editor, EditorState, convertFromRaw } from "draft-js";

const  Assessment  =(props)=> {
    let body = null;
    let contentState = null;
    let editorState = null;
    if(props.data){
        contentState = convertFromRaw(JSON.parse(props.data));
        let decorator = functionForGrabbingAllPluginDecorators();
        editorState = EditorState.createWithContent(contentState, decorator);
        body = (
            <div>
                <Editor editorState={editorState} readOnly={true} />
            </div>
        )
    }
        return ( 
            <div className={css.Assessment}>
                <h1>Assessment</h1>
                {body}

            </div>
         );
    }

 
export default Assessment
