// components/SuccessSnackbar.js or whatever you wanna call it
import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { clearSnackbar } from "../../../store/actions/snackbars-global/get";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
export default function SuccessSnackbar() {
  const dispatch = useDispatch();

  const { errorSnackbarMessage, errorSnackbarOpen } = useSelector(
    state => state.snackbar
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={errorSnackbarOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      aria-describedby="client-snackbar"
    //   message={
    //     <span id="client-snackbar">
    //       <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
    //       {successSnackbarMessage}
    //     </span>
    //   }
    //   action={[
    //     <IconButton
    //       key="close"
    //       aria-label="close"
    //       color="inherit"
    //       onClick={handleClose}
    //     >
    //       <CloseIcon></CloseIcon>
    //     </IconButton>
    //   ]}
    >
          <Alert onClose={handleClose} severity="error">
          {errorSnackbarMessage}
        </Alert>
    </Snackbar>
  );
}