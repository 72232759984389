import React from 'react';
import css from './CardAboutAIM.module.scss';


const cardAboutAIM = (props) => {

    return (
        <div className={css.cardAboutAIM} style={props.style}>

            <div className={css.containerLeft}>
                <h1>
                    What is AIM?
                </h1>
                <p>
                    AIM consist of 3 subsystems specialized in one specific context of the education which works
                    together to achieve the highest learning objectives.
                </p>
                <ul>
                    <li>Student Management</li>
                        <p>
                        o Customized Student dashboard including:<br/>
                            • study path<br/>
                            • semester<br/>
                            • progress [result]<br/>
                            • checkpoints<br/>
                        o Customized study path per student<br/>
                        o Student-staff Communication channel (Mentor, teachers, class discussion)<br/>
                        o Evaluating/Giving feedback of teacher, courses, student<br/>
                        o Exam signup<br/>
                        o Access an overview implied by the type of user<br/>
                        o Presentation of internship portal<br/>
                        </p>
                    <li>Institute Management</li>
                        <p>
                        o Auto-generate student and staff accounts<br/>
                        o Manage the student’s list with the option to add, remove or edit students.<br/>
                        o Creating and modify classes<br/>
                        o Managing classes of students, including adding/ removing students from any class<br/>
                        o Assigning teachers to all classes<br/>
                        o Assigning mentor to each of the first-year classes (and after first year, to each individual student)<br/>
                        o Access an overview implied by the type of user<br/>
                        </p>
                    <li>Curriculum managements</li>
                    <p>
                        o Auto-generate study-paths and courses by importing institute’s booklet.<br/>
                        o Manage study paths, with the option to add and remove modules.<br/>
                        o Create checkpoint (badges) system that indicates their progress.<br/>
                        o Manage modules, with the option to add, remove or edit any module.<br/>
                        o Create and edit course rules.<br/>
                        o Manage dependencies, with the option to add, remove or edit them.<br/>
                        o Different overview of the curriculum implied by the type of user.<br/>
                        o Exporting Course description document.<br/>
                        o Exporting Booklet and study guide documentation.<br/>
                    </p>
                </ul>
            </div>

            <div className={css.containerRight}>
                <h1>video or systems architecture pic.</h1>
                <video width="600" height="400" controls>
                    <source src="https://www.youtube.com/watch?v=2iHoeHVmw0Q" type="video/mp4"/>
                </video>
            </div>
        </div>
    )

}

export default cardAboutAIM;