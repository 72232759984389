export const getRoutes = roles => {
    let routes = require('../routes/student')

    // ROLES
    // Student - roleID: 1
    // Teacher - roleID: 2

    if ( roles.some(role => role.roleName.toLowerCase() === 'student') ) {
        routes = require('../routes/student')
    }

    if ( roles.some(role => role.roleName.toLowerCase() === 'teacher') ) {
        routes = require('../routes/teacher')
    }
    
    if ( roles.some(role => role.roleName.toLowerCase() === 'teamlead') ) {
        routes = require('../routes/teamLead')
    }

    return routes.default
}