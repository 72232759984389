import React, {Suspense} from 'react';

// import components
import Spinner from '../../components/ui/spinners/main-application/Spinner/Spinner'

const waitingComponent = (Component) => {
    return ( props => (
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
    ))
}

export default waitingComponent;