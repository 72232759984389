import * as actionTypes from "../../actions/myclass-information/teacherlists/actionTypes";
import { inputChanged } from '../../../utility/forms/inputChanged'
import produce from "immer";

const initialState = {
  classTeacherLists: null,
  responseData: null,
  error: null,
  loading: null,
  added: false,
  deleted:false,
  teacher: null
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      //GET
      case actionTypes.GET_TEACHERLISTS_START:
        draft.loading = true;
        draft.error = null;
        break;

      case actionTypes.GET_TEACHERLISTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.GET_TEACHERLISTS_SUCCESS:
        draft.loading = false;
        draft.classTeacherLists = action.classTeacherLists;
        break;

      //Post
      case actionTypes.ADD_TEACHERLISTS_START:
        draft.loading = true;
        draft.added = false;
        draft.error = null;
        break;

      case actionTypes.ADD_TEACHERLISTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.ADD_TEACHERLISTS_SUCCESS:
        draft.loading = false;
        draft.responseData = action.responseData;
        draft.added = true;
        break;
      //DELETE
      //Post
      case actionTypes.DELETE_TEACHERLISTS_START:
        draft.loading = true;
        draft.error = null;
        draft.deleted = false;
        break;

      case actionTypes.DELETE_TEACHERLISTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.DELETE_TEACHERLISTS_SUCCESS:
        draft.loading = false;
        draft.responseData = action.responseData;
        draft.deleted = true;
        break;  
      
      default:
    }
  });

export default reducer;
