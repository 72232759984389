import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const getUsers = () => {
    return dispatch => {
        dispatch(getUsersStart())
        axios.get(`/users`)
            .then(response => {  
                dispatch(getUsersSuccess({
                        ...response.data
                    }
                ))
            })
            .catch(error => {              
                dispatch(getUsersFail(error.response))
            })
    }
}

export const getUsersStart = () => {
    return {
        type: actionTypes.GET_USERS_START
    }
}

export const getUsersFail = (error) => {
    return {
        type: actionTypes.GET_USERS_FAIL,
        error: error
    }
}

export const getUsersSuccess = (data) => {
    // console.log(data)
    return {
        type: actionTypes.GET_USERS_SUCCESS,
        users: data
    }
}

export const getUser = (id) => {
    return dispatch => {
        dispatch(getUserStart())
        axios.get(`/user/${id}`)
            .then(response => {
                dispatch(getUserSuccess({
                        ...response.data
                    }
                ))
            })
            .catch(error => {              
                dispatch(getUserFail(error.message))
            })
    }
}

export const getUserStart = () => {
    return {
        type: actionTypes.GET_USER_START
    }
}

export const getUserFail = (error) => {
    return {
        type: actionTypes.GET_USER_FAIL,
        error: error
    }
}

export const getUserSuccess = (data) => {
    // console.log(data)
    return {
        type: actionTypes.GET_USER_SUCCESS,
        info: {
            username: data.username,
            emailSchool: data.emailSchool,
            emailPrivate: data.emailPrivate,
            state: data.state,
            social_id: data.social_id,
            signup_date: data.signup_date,
            last_seen_date: data.last_seen_date,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data.mobile,
            address: data.address,
            postalCode: data.postalCode,
            nationality: data.nationality,
            gender: data.gender,
            pcn: data.pcn,
          },
        user: data
    }
}
