import React from 'react';

// imports from assets
import Student from '../assets/images/Teacher.svg'

// imports from packages
// import { GiSpeedometer, FiGrid, IoMdLink, IoIosNotifications, FiLogOut, IoMdHelpCircleOutline, IoMdInformationCircleOutline, IoMdPeople} from 'react-icons/all';
import { IoMdPeople, FaGraduationCap, GiSpeedometer, IoMdInformationCircleOutline, FiGrid, IoMdLink, IoMdHelpCircleOutline,  IoIosNotifications, FiLogOut, MdBugReport} from 'react-icons/all';

// import hocs
import WaitingComponent from '../hoc/WaitingComponent/WaitingComponent'

// lazy load views
const Dashboard = React.lazy(() => import ('../views/main-application/teacher/Dashboard/Dashboard'))
const TeacherProfilePage = React.lazy(() => import ('../components/ProfilePage/ProfilePage'))
const TeacherFHICTInfo = React.lazy(() => import('../views/main-application/teacher/FHICTInfo/FHICTInfo'))
const Modules = React.lazy(() => import('../views/main-application/teacher/Modules/Modules'))
const Announcements = React.lazy(() => import('../views/main-application/teacher/Announcements/Announcements'))
const Help = React.lazy(() => import("../views/main-application/teacher/Help/Help"));
const UsefulLinks = React.lazy(() => import("../views/main-application/teacher/UsefulLinks/UsefulLinks"));
const StudyPaths = React.lazy(() => import('../views/main-application/teacher/StudyPaths/StudyPaths'))
const Classes = React.lazy(() => import("../views/main-application/teacher/Classes/ClassesPage"));
const ReportProblemPage = React.lazy(() => import("../components/report-problem/reportProblem"));
const routes = [
    {
        text: localStorage.getItem('firstName') ? `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}` : 'Profile Page',
        link: localStorage.getItem('firstName') ? `/${localStorage.getItem('firstName').toLowerCase()}${localStorage.getItem('lastName').toLowerCase()}` : '/profilepage',
        exact: true,
        icon: <img src={Student} alt="Student" />,
        view: WaitingComponent(TeacherProfilePage)
    },
    {
        text: 'Dashboard',
        link: '/',
        exact: true,
        icon: <GiSpeedometer />,
        view: WaitingComponent(Dashboard)
    },
    {
        text: 'My Classes',
        link: '/myclasses',
        icon: <IoMdPeople />,
        view: WaitingComponent(Classes),
        exact: true
    },
    {
        text: 'FHICT Info',
        link: '/fhict-info',
        icon: <IoMdInformationCircleOutline />,
        view: WaitingComponent(TeacherFHICTInfo)
    },
    {
        text: 'Announcements',
        link: '/announcements',
        icon: <IoIosNotifications />,
        view: WaitingComponent(Announcements)
    },
    {
        text: 'All Modules',
        link: '/modules',
        icon: <FiGrid />,
        view: WaitingComponent(Modules),
        exact: true
    },
    {
        text: 'Study Paths',
        link: '/studypaths',
        icon: <FaGraduationCap />,
        view: WaitingComponent(StudyPaths),
        exact: true
    },
    {
        text: 'Useful Links',
        link: '/usefullinks',
        icon: <IoMdLink />,
        view: WaitingComponent(UsefulLinks),
        exact: true
    },
    {
        text: 'Help',
        link: '/help',
        icon: <IoMdHelpCircleOutline />,
        view: WaitingComponent(Help),
        exact: true
    },
   
    {
        link: '/applicationFeedback',
        text: 'Report an Issue',
        icon: <MdBugReport />,
        view: WaitingComponent(ReportProblemPage),

    
    },
    {
        text: 'Log Out',
        icon: <FiLogOut />,
        // Append item to bottom most possition of left sidebar
        last: true
    },
    
]

export default routes