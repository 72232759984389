export const GET_MYCLASSES_START = 'GET_MYCLASSES_START';
export const GET_MYCLASSES_SUCCESS = 'GET_MYCLASSES_SUCCESS';
export const GET_MYCLASSES_FAIL = 'GET_MYCLASSES_FAIL';

export const GET_CLASSANNOUNCEMENTS_START = 'GET_CLASSANNOUNCEMENTS_START';
export const GET_CLASSANNOUNCEMENTS_SUCCESS = 'GET_CLASSANNOUNCEMENTS_SUCCESS';
export const GET_CLASSANNOUNCEMENTS_FAIL = 'GET_CLASSANNOUNCEMENTS_FAIL';





