import * as actionTypes from '../../actions/personal-information/skills/actionTypes'
import produce from 'immer'
import { inputChanged } from '../../../utility/forms/inputChanged'

const initialState = {
    form: {
        controls: {            
            
        },
        isValid: false,
        error: null
    },
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.INPUT_VALUE_CHANGED_SKILLS):
                // update the input value in the state
                inputChanged(draft, action.name, action.value)
                break

            case(actionTypes.GET_SKILLS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_SKILLS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_SKILLS_SUCCESS):
                draft.loading = false

                
                Object.keys(action.skills).forEach(skill => {
                    const skillTitle = action.skills[skill].title
                    const skillID = action.skills[skill].id

                    draft.form.controls[skillID] = {
                        id: skillID,
                        name: skillID,
                        label: skillTitle,
                        value: skillTitle,
                        inputType: 'input',
                        type: 'text',
                        validators: {
            
                        },
                        valid: false,
                        touched: false,
                    }
                })
                break
            
            case(actionTypes.DELETE_SKILLS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.DELETE_SKILLS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.DELETE_SKILLS_SUCCESS):
                draft.loading = false
                const controlsWithoutDeletedInterest = Object.values(draft.form.controls).filter(control => control.id !== action.skillID)
                draft.form.controls = {}
                controlsWithoutDeletedInterest.forEach(skill => {
                    draft.form.controls[skill.id] = skill
                })
                break

            case(actionTypes.POST_SKILLS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.POST_SKILLS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.POST_SKILLS_SUCCESS):
                draft.loading = false

                draft.form.controls[action.skillID] = {
                    id: action.skillID,
                    name: action.skillID,
                    label: action.skillTitle,
                    value: action.skillTitle,
                    inputType: 'input',
                    type: 'text',
                    validators: {
        
                    },
                    valid: false,
                    touched: false,
                }
                
                break

            case(actionTypes.PUT_SKILLS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.PUT_SKILLS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.PUT_SKILLS_SUCCESS):
                draft.loading = false
                break

            default:
        }
    })

export default reducer
