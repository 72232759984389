export const GET_MODULES = 'GET_MODULES'
export const GET_MODULES_START = 'GET_MODULES_START'
export const GET_MODULES_FAIL = 'GET_MODULES_FAIL'
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS'

export const GET_MODULE = 'GET_MODULE'
export const GET_MODULE_START = 'GET_MODULE_START'
export const GET_MODULE_FAIL = 'GET_MODULE_FAIL'
export const GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS'

export const DELETE_MODULES = 'DELETE_MODULES'
export const DELETE_MODULES_START = 'DELETE_MODULES_START'
export const DELETE_MODULES_FAIL = 'DELETE_MODULES_FAIL'
export const DELETE_MODULES_SUCCESS = 'DELETE_MODULES_SUCCESS'

export const POST_MODULES = 'POST_MODULES'
export const POST_MODULES_START = 'POST_MODULES_START'
export const POST_MODULES_FAIL = 'POST_MODULES_FAIL'
export const POST_MODULES_SUCCESS = 'POST_MODULES_SUCCESS'

export const PUT_MODULES = 'PUT_MODULES'
export const PUT_MODULES_START = 'PUT_MODULES_START'
export const PUT_MODULES_FAIL = 'PUT_MODULES_FAIL'
export const PUT_MODULES_SUCCESS = 'PUT_MODULES_SUCCESS'

export const INPUT_VALUE_CHANGED_MODULES_CREATE = 'INPUT_VALUE_CHANGED_MODULES_CREATE'
export const INPUT_VALUE_CHANGED_MODULES_UPDATE = 'INPUT_VALUE_CHANGED_MODULES_UPDATE'