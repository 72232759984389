import React from 'react';
import css from './Row.module.scss'

const row = (props) => {
    const rowStyles = [css.Row]
    if (props.equal) {
        rowStyles.push(css.Equal)
    }

    if (props.marginBottom) {
        rowStyles.push(css.MarginBottom)
    }

    return ( 
        <div className={rowStyles.join(' ')}>
            {props.children}
        </div>
     );
}
 
export default row;