import * as actionTypes from "../../actions/myclass-information/modules/actionTypes";
import produce from "immer";

const initialState = {
  classModules: null,
  error: null,
  loading: null,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_MODULES_START:
        draft.loading = true;
        draft.error = null;
        break;

      case actionTypes.GET_MODULES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case actionTypes.GET_MODULES_SUCCESS:
        draft.loading = false;
        draft.classModules = action.classModules;
        break;
      default:
    }
  });

export default reducer;
