export const GET_ANNOUNCEMENTS_START = 'GET_ANNOUNCEMENTS_START';
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const GET_ANNOUNCEMENTS_FAIL = 'GET_ANNOUNCEMENTS_FAIL';

export const ADD_ANNOUNCEMENT_START = 'ADD_ANNOUNCEMENT_START';
export const ADD_ANNOUNCEMENT_SUCCESS = 'ADD_ANNOUNCEMENT_SUCCESS';
export const ADD_ANNOUNCEMENT_FAIL = 'ADD_ANNOUNCEMENT_FAIL';

export const DELETE_ANNOUNCEMENT_START = 'DELETE_ANNOUNCEMENT_START';
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';
export const DELETE_ANNOUNCEMENT_FAIL = 'DELETE_ANNOUNCEMENT_FAIL';

export const FETCH_USER_ANNOUNCEMENTS_START = 'FETCH_USER_ANNOUNCEMENTS_START';
export const FETCH_USER_ANNOUNCEMENTS_SUCCESS = 'FETCH_USER_ANNOUNCEMENTS_SUCCESS';
export const FETCH_USER_ANNOUNCEMENTS_FAIL = 'FETCH_USER_ANNOUNCEMENTS_FAIL';