import React, { Component } from 'react';
import css from './Requirements.module.scss'
import Button from '../../../../../../components/ui/buttons/main-application/Button/Button'
import Row from '../../../../../../components/ui/grid/Row/Row'

import {withRouter} from 'react-router-dom'

const Requirements = (props) => {
 
    let body = null;

   const userNavigatedToModuleHandler = (code) => {
        props.history.push(`/modules/${code}`)
    }
   
        body = Object.values(props.data).map((item, key) => {
                return  <Button onClick={() => userNavigatedToModuleHandler(item.code)} key={key} >{item.name}</Button> 
            }) 

         return ( 
            <div className={css.Requirements}>
                <h1>Requirements</h1>
                <h2>Required modules</h2>
                <Row>
                {body}
                </Row>
            </div>
         );
    
}
 
export default withRouter(Requirements)
