import * as actionTypes from '../../actions/classes-teacher/personalNotes/actionTypes'
import produce from 'immer'

const initialState = {
    personalNotes: [],
    personalNotesList: [
        {   
            id: 0, 
            classID: 1,
            teacherID: 14,
            studentID: 15,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            date: '12.01.2021',
            subject: "asd asd asd asd",
            note: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
        },
        {   
            id: 2, 
            classID: 1,
            teacherID: 14,
            studentID: 4,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            date: '12.01.2021',
            subject: "asd asd asd asd",
            note: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
        },
        {   
            id: 3, 
            classID: 1,
            teacherID: 14,
            studentID: 7,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            date: '12.01.2021',
            subject: "asd asd asd asd",
            note: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
        },
        {  
            id: 4, 
            classID: 1,
            teacherID: 14,
            studentID: 10,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            date: '12.01.2021',
            subject: "asd asd asd asd",
            note: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
        },
        {   
            id: 5, 
            classID: 1,
            teacherID: 14,
            studentID: 13,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            date: '12.01.2021',
            subject: "asd asd asd asd",
            note: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
        },
        {   
            id: 6, 
            classID: 1,
            teacherID: 14,
            studentID: 14,
            teacherFullName: "Asd asd",
            moduleCode: "ASD",
            moduleName: "asd asd asd asd",
            date: '12.01.2021',
            subject: "asd asd asd asd",
            note: "{\"blocks\":[{\"key\":\"8os6p\",\"text\":\"Dear all, \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1god6\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1q18l\",\"text\":\"I'm very happy to annouce the deployment of our AIM Version 2 which has been under development during the last 6 month. \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":48,\"length\":13,\"style\":\"BOLD\"},{\"offset\":48,\"length\":13,\"style\":\"UNDERLINE\"},{\"offset\":105,\"length\":13,\"style\":\"ITALIC\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"8c1m8\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"88ps7\",\"text\":\"we hope that this tool will help to do our job in a more efficient way.\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"1i5bb\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"cd6gc\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}},{\"key\":\"2c9fr\",\"text\":\"Kind regards,\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":13,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}},{\"key\":\"ftan7\",\"text\":\"Mikaeil\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[{\"offset\":0,\"length\":7,\"style\":\"BOLD\"},{\"offset\":0,\"length\":7,\"style\":\"CODE\"}],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"
    
        }
    ],
    error: null,
    loading: false,
    form: {
        controls: {
       
        subject:
          {
            label: "Subject",
            value: '',
            inputType: "input",
            type: "text",
            multiline: false,
          },
        
        },
        isValid: false,
        error: null,
      }
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.GET_PERSONALNOTES_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_PERSONALNOTES_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_PERSONALNOTES_SUCCESS):
                draft.loading = false
                let tempDataCopy = [...draft.personalNotesList]
              

                tempDataCopy = tempDataCopy.filter(grade=> grade.studentID === action.userID)
                
              
                draft.personalNotes = tempDataCopy
                break

               

                case(actionTypes.POST_PERSONALNOTES_START):
            draft.loading = true;
            draft.error = null;
            break
        case(actionTypes.POST_PERSONALNOTES_FAIL):
            draft.loading = false;
            draft.error = action.error;
            break
        case(actionTypes.POST_PERSONALNOTES_SUCCESS):
        
            draft.adding = true;
            draft.loading = false;
            if(action.data){
                draft.personalNotesList.push(action.data)
                draft.personalNotes.push(action.data)

            }
            if(draft.form){
                Object.keys(draft.form.controls).forEach(controlName => {
                    draft.form.controls[controlName].value=null
                })
            }
            break
            
            default:
        }
    })


export default reducer
