import React, { Component, Fragment } from 'react';
import css from './Login.module.scss'
import avatar from '../../../assets/images/AIM.png'

// import components
import LoginForm from './LoginForm/LoginForm'
import RegisterForm from './RegisterForm/RegisterForm'

// imports from packages
import produce from 'immer'

class Login extends Component {
    state = {
        loggingIn: true
    }

    switchToRegisterHandler = props => {
        this.setState(
            produce(draft => {
                draft.loggingIn = false
            })
        )
    }

    switchToLoginHandler = props => {
        this.setState(
            produce(draft => {
                draft.loggingIn = true
            })
        )
    }

    render() {

        let activeForm = (
            <Fragment>
                <LoginForm />
                <p className={css.ChangeForm} onClick={this.switchToRegisterHandler}>New here?</p>
            </Fragment>
        )

        if (!this.state.loggingIn) {
            activeForm = (
                <Fragment>
                    <RegisterForm switchToLogIn={this.switchToLoginHandler}/>
                    <p className={css.ChangeForm} onClick={this.switchToLoginHandler}>Log in!</p>
                </Fragment>
            )
        }

        return (
            <div className={css.Login}>
                <div className={css.Avatar}>
                    <img src={avatar} alt="AIM" />
                </div>
                {activeForm}

            </div>
        );
    }
}

export default Login;