import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const updateUser = (userID, updatedData) => {
    return dispatch => {
        dispatch(updateUserStart())
        axios.put(`/user/${userID}`, {...updatedData, id: userID})
            .then(response => {  
                dispatch(updateUserSuccess({
                        ...response.data
                    }
                ))
            })
            .catch(error => {             
                dispatch(updateUserFail(error.message))
            })
    }
}

export const updateUserStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START
    }
}

export const updateUserFail = (error) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        error: error
    }
}

export const updateUserSuccess = (info) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        info: {
            username: info.username,
            emailSchool: info.emailSchool,
            emailPrivate: info.emailPrivate,
            state: info.state,
            social_id: info.social_id,
            signup_date: info.signup_date,
            last_seen_date: info.last_seen_date,
            firstName: info.firstName,
            lastName: info.lastName,
            mobile: info.mobile,
            address: info.address,
            postalCode: info.postalCode,
            nationality: info.nationality,
            gender: info.gender,
            pcn: info.pcn,
        }
        
    }
}
