import * as actionTypes from "./actionTypes";
import axios from "../../../axios/localhost";

export const addUser = (id, data) => {
  return (dispatch) => {
    dispatch(addUserStart());
    axios
      .post(`/user/${id}`, data)
      .then((response) => {
        dispatch(addUserSuccess(data));
      })
      .catch((error) => {
        dispatch(addUserFail(error));
      });
  };
};

export const addUserStart = () => {
  return {
    type: actionTypes.ADD_USER_START,
  };
};

export const addUserFail = (error) => {
  return {
    type: actionTypes.ADD_USER_FAIL,
    error: error,
  };
};

export const addUserSuccess = (data) => {
  return {
    type: actionTypes.ADD_USER_SUCCESS,
    data: data,
  };
};
