import React from 'react'

// import hocs
import WaitingComponent from '../hoc/WaitingComponent/WaitingComponent'

const Home = React.lazy(() => import('../views/landing-page/Home/Home'))
const About = React.lazy(() => import('../views/landing-page/About/About'))
const Login = React.lazy(() => import('../views/landing-page/Login/Login'))

export const routes = [
    {
        text: 'AIM!',
        link: '/',
        exact: true,
        component: WaitingComponent(Home)
    },

    {
        text: 'Login',
        link: '/login',
        component: WaitingComponent(Login),
    }
]