import * as actionTypes from "../../actions/classes-teamlead/actionTypes";
import { inputChanged } from '../../../utility/forms/inputChanged'
import produce from "immer";

const initialState = {
  classes: null,
  error: null,
  loading: true,
  added: false,
  deleted: false,
  responceData: null,
  edited: false,
  form: {
    controls: {
      id: {
        label: "ID",
        value: '',
        inputType: "input",
        type: "text",
        validators: {
                    
        },
        valid: false,
        touched: false,
      },
      classCode: {
        label: "Class Code",
        value: '',
        inputType: "input",
        type: "text",
        required: true,
        validators: {
                    
        },
        valid: false,
        touched: false,
      },
      description: {
        label: "Description",
        value: '',
        inputType: "input",
        type: "text",
        validators: {
                    
        },
        required: true,
        valid: false,
        touched: false,
      },
      mentorID: {
        label: "Mentor ID",
        value: '',
        inputType: "input",
        type: "text",
        validators: {
                    
        },
        required: true,
        valid: false,
        touched: false,
      },
      studyPathID: {
        label: "Study Path ID",
        value: '',
        inputType: "input",
        type: "text",
        validators: {
                    
        },
        required: true,
        valid: false,
        touched: false,
      },
      semester: {
        label: "Semester",
        value: '',
        inputType: "input",
        type: "text",
        validators: {
                    
        },
        required: true,
        valid: false,
        touched: false,
      }
    },
    isValid: false,
    error: null,
  },
  modules: [],
  class: null
};

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      //change input
      case(actionTypes.INPUT_VALUE_CHANGED_CLASSES_UPDATE):
          // update the input value in the state
          inputChanged(draft, action.name, action.value)
        break

      case(actionTypes.INPUT_VALUE_CHANGED_CLASSES_CREATE):
          inputChanged(draft, action.name, action.value)
        break
      //GET ALL
      case actionTypes.GET_CLASSES_START:
        draft.loading = true;
        draft.error = null;
        draft.added = false;
        draft.deleted = false;
        draft.form = draft.form;
        break;
      case actionTypes.GET_CLASSES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_CLASSES_SUCCESS:
        draft.loading = false;
        draft.classes = action.classes;
        break;

      //GET SPECIFIC
      case actionTypes.GET_CLASS_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.GET_CLASS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_CLASS_SUCCESS:
        draft.loading = false;
        draft.modules = action.modules;
        draft.class = action.class;
        // populate state form controls
        if(action.info){
          Object.keys(action.info).forEach((controlName) => {
            draft.form.controls[controlName].value = action.info[controlName]
            ? action.info[controlName]
              : '';
          });
        }
        break;

      //CREATE
      case actionTypes.ADD_CLASS_START:
        draft.loading = true;
        draft.error = null;
        draft.added = false;
        draft.deleted = false;
        break;
      case actionTypes.ADD_CLASS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.added = false;
        break;
      case actionTypes.ADD_CLASS_SUCCESS:
        draft.loading = false;
        draft.responceData = action.responceData;
        draft.added = true;
        break;

      //UPDATE
      case actionTypes.PUT_CLASS_START:
        draft.loading = true;
        draft.edited = false;
        draft.error = null;
        break;
      case actionTypes.PUT_CLASS_FAIL:
        draft.loading = false;
        draft.edited = false;
        draft.error = action.error;
        break;
      case actionTypes.PUT_CLASS_SUCCESS:
        draft.loading = false;
        draft.responceData = action.responceData;
        draft.edited = true;
        break;

      //DELETE
      case actionTypes.DELETE_CLASS_START:
        draft.loading = true;
        draft.added = false;
        draft.deleted = false;
        draft.error = null;
        break;
      case actionTypes.DELETE_CLASS_FAIL:
        draft.loading = false;
        draft.deleted = false;
        draft.error = action.error;
        break;
      case actionTypes.DELETE_CLASS_SUCCESS:
        draft.loading = false;
        draft.responceData = action.responceData;
        draft.deleted = true;
        break;

      default:
    }
  });

export default reducer;
