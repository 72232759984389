import React from 'react';
import css from './Button.module.scss'

const button = (props) => {
    const buttonStyles = [css.Button]
    
    if (props.light) buttonStyles.push(css.Light)

    return ( 
        <button onClick={props.onClick} style={props.style} className={buttonStyles.join(' ')} disabled={props.disabled}>
            {props.children}
        </button>
     );
}
 
export default button;