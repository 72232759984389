import React, { Component, Suspense } from 'react'
import css from './App.module.scss'

// import layouts, lazy load the main application, so it will only be loaded after successful login
import LandingPageLayout from './layouts/landing-page/LandingPageLayout/LandingPageLayout'

// import landing page views
import Home from './views/landing-page/Home/Home'
import Login from './views/landing-page/Login/Login'
import About from './views/landing-page/About/About'

// import main application views
import ModuleView from './views/main-application/team-lead/Modules/Module/Module'
import UserView from './views/main-application/team-lead/UsersPage/UserPage/UserPage'

// import components
import Spinner from './components/ui/spinners/main-application/Spinner/Spinner'

// import from packages
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

// redux store
import { connect } from 'react-redux';
import { authCheckState } from './store/actions/auth'

// import utility functions
import { getRoutes } from './utility/getRoutes'
import SuccessSnackbar from './components/ui/snackbars/snackbarSuccess'
import ErrorSnackBar from './components/ui/snackbars/snackbarError'


// Lazy loading components
const MainApplicationLayout = React.lazy(() => import('./layouts/main-application/MainApplicationLayout/MainApplicationLayout'))

class App extends Component {
    state = {}

    componentDidMount() {
        this.props.checkAuthState();
    }

	// rendering the application
	render() {
		let app= (
			<LandingPageLayout>
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/login" exact component={Login} />
                  
					<Redirect to="/login" />
				</Switch>
			</LandingPageLayout>
		)

		if (this.props.userID) {
            
            const roleRoutes = getRoutes(this.props.roles)

            const routes = roleRoutes.map((route, key) => (
                <Route key={key} path={route.link} component={route.view} exact={route.exact} />
            ))

            app = (
                <Suspense fallback={<Spinner />}>
                    <MainApplicationLayout>
                  
                        {routes}
                        <Route path="/modules/:code" exact component={ModuleView} />
                        <Route path="/user/:code" exact component={UserView} />
    					<Redirect to='/' />
                    </MainApplicationLayout>
                </Suspense>
            );
            // return app;
        }

        return (
            <div className={css.App}>
              <SuccessSnackbar></SuccessSnackbar>
              <ErrorSnackBar></ErrorSnackBar>
                <BrowserRouter>
                    <Switch>
                        {app}
                    </Switch>
                </BrowserRouter>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        userID: state.auth.userID,
        roles: state.auth.roles,
        firstName: state.auth.firstName,
        lastName: state.auth.lastName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkAuthState: () => dispatch(authCheckState())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
