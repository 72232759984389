import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import css from  './CardTabs.module.scss';
import {GrContactInfo, RiQuestionnaireFill,FaUserGraduate,GoChecklist} from  "react-icons/all";
import DemoGif from '../../../../assets/images/featured.png';
import Grid from '@material-ui/core/Grid';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    marginTop: '3rem',
    
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
   
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightRegular,
   

    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },

  demo2: {
    backgroundColor: '#2e1534',
  },
}));

export default function CustomizedTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box  p={2}>
    <div className={classes.root}>
      <div className={css.antTabs}>
        <AntTabs centered value={value} onChange={handleChange} aria-label="ant example">
          <AntTab icon={< RiQuestionnaireFill size={40} />} label="What is AIM" />
          <AntTab icon={< GrContactInfo size={46}/>}  label="Why AIM" />
          <AntTab icon={<FaUserGraduate size={38}/>} label="How to AIM" />
         
        </AntTabs>
        <Typography className={classes.padding} />
      </div>
     
      <TabPanel value={value} index={0}>
      <Grid container>
      {/* <Grid  justify="center"
            container
            direction="row" alignItems="center"item xs={12} sm={6} ><img src={DemoGif} alt="AIM"/></Grid>  */}
      <Grid  item xs={12} sm={12}  >
      <h1>
                    What is AIM?
                </h1>
                <p>
                We believe everyone in an educational institute deserves a personalised dashboard to enhance their productivity in the learning-teaching life cycle.
Our solution is a role based centralized data center to improve the efficiency of activities based on reliable and up-to-date information.

<p>AIM is an educational platform for Open learning, consisting of 3 independent subsystems specialized in one specific context of the education. </p>

<p><b>Student Management: </b> for students to visualize and plan their study path to achieve the highest learning objectives </p>

<p><b>Institute Management:  </b> for institutes to save time and achieve the highest performance.</p>

<p> <b> Curriculum management: </b>Create open education environment using 4CI/D and Connect businesses to institutes </p>

                </p>

        </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
       <h1>Why do you need to AIM?</h1>
      <p>
        Overwhelming number of incompatible tools and systems leads to a chaos in the educational environments in which predictability of the learning outcomes is a must. This makes a quite big number of problems that makes daily tasks exhausting.
      </p>
      <ul>
        <li>	Using outdated and brand-new tools with incompatibility issues </li>
        <li>	Conflict in archives</li>
        <li>	Lack of clear overview</li>
        <li>	Missing track of small details</li>
        <li>	Missing an evaluation system</li>
        <li>  Missing feedback system</li>
        <li>	Missing information for better advice </li>
        <li>	Tedious repetitive manual tasks which requires knowledge of all rules</li>
        <li>	Time consuming</li>
      </ul>
      <p>Working in these environments keeps people away from their passion and goals and brings the concentration in finding your way through rather than making the best out of the educational time. Only in highly monitored environments people can find the creative possibilities</p>
      </TabPanel>
      <TabPanel value={value} index={2}>
         <h1>
           How to AIM
        </h1>
      
        <p>
        Start by migrating your goals to our program and make use of the role based dashboards to save time and never miss even a minor detail. 
        </p>
      </TabPanel>
    
    </div>
    </Box>
  );
}
