import React from "react";
import {
    AiFillRightSquare,
    AiFillLeftSquare
  
  } from "react-icons/all";

import "./Carousel.scss";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import img1 from '../../../../assets/images/01.jpg';
import img2 from '../../../../assets/images/02.jpg'
import img3 from '../../../../assets/images/03.jpg'
import img4 from '../../../../assets/images/04.jpg'
import img5 from '../../../../assets/images/05.jpg'
import img6 from '../../../../assets/images/06.jpg'
import img7 from '../../../../assets/images/07.jpg'
import img8 from '../../../../assets/images/08.jpg'
export default class extends React.Component {
  render() {
    return (
      <CarouselProvider
        infinite ={true}
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={8}
        visibleSlides={1}
        currentSlide={2}
      >

        <Slider>
          <Slide  index={0}><img src={img1}></img></Slide>
          <Slide index={1}><img src={img2}></img></Slide>
          <Slide index={2}><img src={img3}></img></Slide>
          <Slide index={3}><img src={img4}></img></Slide>
          <Slide index={4}><img src={img5}></img></Slide>
          <Slide index={5}><img src={img6}></img></Slide>
          <Slide index={6}><img src={img7}></img></Slide>
          <Slide index={7}><img src={img8}></img></Slide>
          
        </Slider>
        <div className="button__container">
            <ButtonBack><AiFillLeftSquare/></ButtonBack>
            <ButtonNext><AiFillRightSquare/></ButtonNext>
        </div>
      </CarouselProvider>
    );
  }
}
