import React from "react";
import Slider from "./Slider";
import iphonebg from '../../../../assets/images/iphonebg.png'
import "./Carousel.scss";


export default function App() {
  return (
    <div className="App">
    <div className="carousel__wrapper">
      <div className="carousel__container">
          <div className="screenshot-app-bg"><img src={iphonebg} /></div>
        <Slider/>
      </div>
      </div>
    </div>
  );
}
