import React from 'react';
import css from './EditForm.module.scss'

import Button from '@material-ui/core/Button';
import Input from '../../../../../../components/forms/main-application/Input/Input'

// connect with Redux store
import { connect } from 'react-redux'
import { updateUser } from '../../../../../../store/actions/users/index';

export const EditForm = (props) => {
    const submitHandler = (event) => {
        event.preventDefault()

        const updatedData = {id: props.id}
        Object.keys(props.form.controls).forEach(controlName => {
            updatedData[controlName] = props.form.controls[controlName].value
        })
        props.onFormSubmit(props.id, updatedData)
    }

    return ( 
        <form className={css.GeneralInformationForm} onSubmit={(event) => submitHandler(event)}>
                {Object.keys(props.form.controls).map((controlName, key) => {
                    const config = props.form.controls[controlName]
                    return (
                        <div className={css.InputGroup} key={key}>
                            <Input 
                                data-test="inputs"
                                inputType={config.inputType}
                                type={config.type}
                                name={controlName}
                                changed={props.inputChanged}
                                label={config.label}
                                required={config.required}
                                placeholder={controlName}
                                value={config.value}
                                valid={config.valid}
                                touched={config.touched}
                                options={config.options} 
                                disabled={config.disabled}/>     
                        </div>                                                      
                    )
                })}
                <Button variant="contained" color="primary" type="submit">Submit</Button>
            </form>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onFormSubmit: (userID, updatedData) => dispatch(updateUser(userID, updatedData))
    }
}

export default connect(null, mapDispatchToProps)(EditForm)
