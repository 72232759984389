export const GET_STUDENTLISTS = "GET_STUDENTLISTS";
export const GET_STUDENTLISTS_START = "GET_STUDENTLISTS_START";
export const GET_STUDENTLISTS_FAIL = "GET_STUDENTLISTS_FAIL";
export const GET_STUDENTLISTS_SUCCESS = "GET_STUDENTLISTS_SUCCESS";

export const ADD_STUDENTLISTS_START = "ADD_STUDENTLISTS_START";
export const ADD_STUDENTLISTS_FAIL = "ADD_STUDENTLISTS_FAIL";
export const ADD_STUDENTLISTS_SUCCESS = "ADD_STUDENTLISTS_SUCCESS";

export const DELETE_STUDENTLISTS_START = "DELETE_STUDENTLISTS_START";
export const DELETE_STUDENTLISTS_FAIL = "DELETE_STUDENTLISTS_FAIL";
export const DELETE_STUDENTLISTS_SUCCESS = "DELETE_STUDENTLISTS_SUCCESS";