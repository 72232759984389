import React, { Component } from 'react';
import css from './About.module.scss';

import Footer from '../Home/Footer/Footer'
import CardAboutAIM from '../About/CardAboutAIM/CardAboutAIM'




class About extends Component {
    render() { 
        return ( 

            <div className={[css.About, css.CardLandingPage].join(' ')}>
                <CardAboutAIM>

                </CardAboutAIM>
                <Footer>
                    
                </Footer>

            </div>            
        );
    }
}
 
export default About;