export const GET_USERS = 'GET_USERS'
export const GET_USERS_START = 'GET_USERS_START'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'

export const GET_USER_START = 'GET_USER_START'
export const GET_USER_FAIL = 'GET_USER_FAIL'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'

export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const INPUT_VALUE_CHANGED_USER = 'INPUT_VALUE_CHANGED_USER'