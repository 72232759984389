import * as actionTypes from '../../actions/gradingModels-teamlead/actionTypes'
import produce from 'immer'

const initialState = {
    gradingModels: null,
    error: null,
    loading: false,
    gradingModel: null
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.GET_GRADINGMODELS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_GRADINGMODELS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_GRADINGMODELS_SUCCESS):
                draft.loading = false

                // populate state form controls
                draft.gradingModels = action.gradingModels
                break

                  //GET SPECIFIC
      case actionTypes.GET_GRADINGMODEL_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.GET_GRADINGMODEL_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.gradingModel = null;
        break;
      case actionTypes.GET_GRADINGMODEL_SUCCESS:
        draft.loading = false;
        draft.modules = action.modules;
        draft.class = action.class;
        // populate state form controls
        if(action.info){
        draft.gradingModel = action.info;
        }
        break;

            case(actionTypes.ADD_GRADINGMODEL_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.ADD_GRADINGMODEL_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.ADD_GRADINGMODEL_SUCCESS):
                draft.adding = true
                draft.loading = false
                if(action.data){
                    draft.gradingModels.payload.push(action.data)
                }
                if(draft.form){
                    Object.keys(draft.form.controls).forEach(controlName => {
                        draft.form.controls[controlName].value=null
                    })
                }                
                break
           // UPDATE
      case actionTypes.PUT_GRADINGMODEL_START:
        draft.loading = true;
        draft.edited = false;
        draft.error = null;
        break;
      case actionTypes.PUT_GRADINGMODEL_FAIL:
        draft.loading = false;
        draft.edited = false;
        draft.error = action.error;
        break;
      case actionTypes.PUT_GRADINGMODEL_SUCCESS:
        draft.loading = false;
        draft.responceData = action.responceData;
        draft.edited = true;
        break;
         //DELETE
      case actionTypes.DELETE_GRADINGMODEL_START:
        draft.loading = true;
        draft.added = false;
        draft.deleted = false;
        draft.error = null;
        break;
      case actionTypes.DELETE_GRADINGMODEL_FAIL:
        draft.loading = false;
        draft.deleted = false;
        draft.error = action.error;
        break;
      case actionTypes.DELETE_GRADINGMODEL_SUCCESS:
        draft.loading = false;
        draft.responceData = action.responceData;
        draft.deleted = true;
        break;
            
            default:
        }
    })

export default reducer
