export const DELETE_GENERAL_INFORMATION = 'DELETE_GENERAL_INFORMATION'
export const DELETE_GENERAL_INFORMATION_START = 'DELETE_GENERAL_INFORMATION_START'
export const DELETE_GENERAL_INFORMATION_FAIL = 'DELETE_GENERAL_INFORMATION_FAIL'
export const DELETE_GENERAL_INFORMATION_SUCCESS = 'DELETE_GENERAL_INFORMATION_SUCCESS'

export const GET_GENERAL_INFORMATION = 'GET_GENERAL_INFORMATION'
export const GET_GENERAL_INFORMATION_START = 'GET_GENERAL_INFORMATION_START'
export const GET_GENERAL_INFORMATION_FAIL = 'GET_GENERAL_INFORMATION_FAIL'
export const GET_GENERAL_INFORMATION_SUCCESS = 'GET_GENERAL_INFORMATION_SUCCESS'

export const POST_GENERAL_INFORMATION = 'POST_GENERAL_INFORMATION'
export const POST_GENERAL_INFORMATION_START = 'POST_GENERAL_INFORMATION_START'
export const POST_GENERAL_INFORMATION_FAIL = 'POST_GENERAL_INFORMATION_FAIL'
export const POST_GENERAL_INFORMATION_SUCCESS = 'POST_GENERAL_INFORMATION_SUCCESS'

export const PUT_GENERAL_INFORMATION = 'PUT_GENERAL_INFORMATION'
export const PUT_GENERAL_INFORMATION_START = 'PUT_GENERAL_INFORMATION_START'
export const PUT_GENERAL_INFORMATION_FAIL = 'PUT_GENERAL_INFORMATION_FAIL'
export const PUT_GENERAL_INFORMATION_SUCCESS = 'PUT_GENERAL_INFORMATION_SUCCESS'

export const INPUT_VALUE_CHANGED_GENERAL_INFORMATION = 'INPUT_VALUE_CHANGED_GENERAL_INFORMATION'
