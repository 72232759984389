import React from 'react';
import css from './TimePlanning.module.scss'
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { functionForGrabbingAllPluginDecorators } from '../../../../../../utility/functionForGrabbingAllPluginDecorators';

const TimePlanning = (props)=> {
    let body = null;
    let contentState = null;
    let editorState = null;
    if(props.data){
        contentState = convertFromRaw(JSON.parse(props.data));
        let decorator = functionForGrabbingAllPluginDecorators();
        editorState = EditorState.createWithContent(contentState, decorator);
        body = (
            <div>
                <Editor editorState={editorState} readOnly={true} />
            </div>
        )
    }
    return ( 
        <div className={css.TimePlanning}>
            <h1>TimePlanning</h1>
            {body}
        </div>
    );
}
export default TimePlanning;