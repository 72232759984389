import * as actionTypes from './actionTypes';
import axios from '../../../axios/localhost';


// Check current authentication state
export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            // dispatch(logout());
            alert("Your session expired!");
        }, expirationTime * 10000);
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            //dispatch(logout());
            console.log("Please log in to view and use the application!");
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const userID = localStorage.getItem('userID');
            const token = localStorage.getItem('token');           
            const roles = JSON.parse(localStorage.getItem('roles'))
            const firstName = localStorage.getItem('firstName')
            const lastName = localStorage.getItem('lastName')

            if (expirationDate > new Date()){
                dispatch(loginSuccess({
                    userID: userID,
                    token: token,
                    role: roles,
                    firstName: firstName,
                    lastName: lastName

                }));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/1000));
            }
            else{
                dispatch(logout())
            }
        }
    }
}


// Login
export const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
}

export const loginSuccess = (loginData) => {

    return {
        type: actionTypes.LOGIN_SUCCESS,
        userID: loginData.userID,
        roles: loginData.role,
        token: loginData.token,
        firstName: loginData.firstName,
        lastName: loginData.lastName
    }
}

export const loginFail = (error) => {
    return {
        type: actionTypes.LOGIN_FAIL,
        error: error
    }
}

export const login = (email, password) => {
    return dispatch => {
        dispatch(loginStart())

        let loginData = {
            email: email,
            password: password
        }

        axios.post('/user/login', loginData)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.duration * 1000);

                localStorage.setItem('token', response.data.token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userID', response.data.userID);
                localStorage.setItem('roles', JSON.stringify(response.data.role))
                localStorage.setItem('firstName', response.data.firstName)
                localStorage.setItem('lastName', response.data.lastName)

                dispatch(loginSuccess({
                    ...response.data
                }));
                dispatch(checkAuthTimeout(response.data.duration));
            })
            .catch(error => {

                dispatch(loginFail(error.response.data.message));
            })
    }

}


// Registration
export const registerStart = () => {
    return {
        type: actionTypes.REGISTER_START
    }
}

export const registerSuccess = (message) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        message: message
    }
}

export const registerFail = (message) => {
    return {
        type: actionTypes.REGISTER_FAIL,
        message: message
    }
}

export const register = (firstName, lastName, email, password, role) => {
    return dispatch => {
        dispatch(registerStart());

        let registerData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            role: role
        }

        let url = '/user/register';
        
        axios.post(url, registerData)
            .then(response => {
                dispatch(registerSuccess(response.data.message));
            })
            .catch(error => {
                dispatch(registerFail(error.response.data.message));
            });
    }
}


// Logout
export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userID');
    localStorage.removeItem('roles')
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}










