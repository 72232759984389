export const GET_BADGES_START = 'GET_BADGES_START';
export const GET_BADGES_SUCCESS = 'GET_BADGES_SUCCESS';
export const GET_BADGES_FAIL = 'GET_BADGES_FAIL';

export const ADD_BADGE_START = 'ADD_BADGES_START';
export const ADD_BADGE_SUCCESS = 'ADD_BADGES_SUCCESS';
export const ADD_BADGE_FAIL = 'ADD_BADGES_FAIL';

export const DELETE_BADGE_START = 'DELETE_BADGES_START';
export const DELETE_BADGE_SUCCESS = 'DELETE_BADGES_SUCCESS';
export const DELETE_BADGE_FAIL = 'DELETE_BADGES_FAIL';

export const GET_BADGE_START = 'GET_BADGES_START';
export const GET_BADGE_SUCCESS = 'GET_BADGES_SUCCESS';
export const GET_BADGE_FAIL = 'GET_BADGES_FAIL';

export const PUT_BADGE_START = 'PUT_BADGES_START';
export const PUT_BADGE_SUCCESS = 'PUT_BADGES_SUCCESS';
export const PUT_BADGE_FAIL = 'PUT_BADGES_FAIL';

export const INPUT_VALUE_CHANGED_BADGES_CREATE = 'INPUT_VALUE_CHANGED_BADGES_CREATE';
export const INPUT_VALUE_CHANGED_BADGES_UPDATE = 'INPUT_VALUE_CHANGED_BADGES_UPDATE';