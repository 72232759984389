import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/scss/normalize.scss";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";

// redux store
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import authReducer from "./store/reducers/auth";
import generalInformationReducer from "./store/reducers/personal-information/generalInfo";
import interestsReducer from "./store/reducers/personal-information/interests";
import changepasswordReducer from "./store/reducers/personal-information/password";
import skillsReducer from "./store/reducers/personal-information/skills";
import workingExperienceReducer from "./store/reducers/personal-information/workingExperience";
import previousEducationReducer from "./store/reducers/personal-information/previousEducation";
import studyPathsReducer from "./store/reducers/study-paths/studyPaths";
import modulesReducer from "./store/reducers/modules/modules";
import instituteStatsReducer from "./store/reducers/institute-stats/instituteStats";
import studentStatsReducer from "./store/reducers/student-stats/studentStats";
import profilephotoReducer from "./store/reducers/personal-information/profilephoto";
import messagesReducer from "./store/reducers/messages/messages";
import gradesReducer from "./store/reducers/grades/grades";
import feedbackReducer from "./store/reducers/feedback/feedback";
import personalNotesReducer from "./store/reducers/personalNotes/personalNotes";
import usersReducer from "./store/reducers/users/users";
import classesReducer from "./store/reducers/classes/classes";
import myclassesReducer from "./store/reducers/classes/myclasses";
import teacherClassesReducer from "./store/reducers/classes/teacherClasses";
import announcementsReducer from "./store/reducers/announcements/announcements";
import classOverviewReducer from "./store/reducers/myclass-information/overview";
import classMentorReducer from "./store/reducers/myclass-information/mentor";
import classStudentListsReducer from "./store/reducers/myclass-information/studentLists";
import classTeacherListsReducer from "./store/reducers/myclass-information/teacherLists";
import classModulesReducer from "./store/reducers/myclass-information/modules";
import institutesReducer from "./store/reducers/institutes/institutes";
import gradingModelsReducer from "./store/reducers/gradingModels-teamlead/gradingModels";
import badgesReducer from "./store/reducers/badges-teamled/badges";
import snackbarReducer from "./store/reducers/snackbars-global/snackbars";
import classAnnouncementsReducer from "./store/reducers/classes/classAnnouncements"


import thunk from "redux-thunk";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  auth: authReducer,
  generalInformation: generalInformationReducer,
  interests: interestsReducer,
  skills: skillsReducer,
  workingExperience: workingExperienceReducer,
  previousEducation: previousEducationReducer,
  studyPaths: studyPathsReducer,
  modules: modulesReducer,
  instituteStats: instituteStatsReducer,
  studentStats: studentStatsReducer,
  messages: messagesReducer,
  users: usersReducer,
  classOverview: classOverviewReducer,
  classMentor: classMentorReducer,
  classStudentLists: classStudentListsReducer,
  classTeacherLists: classTeacherListsReducer,
  classModules: classModulesReducer,
  profilephoto: profilephotoReducer,
  announcements: announcementsReducer,
  classes: classesReducer,
  myclasses:myclassesReducer,
  changepassword:changepasswordReducer,
  institutes: institutesReducer,
  teacherClasses: teacherClassesReducer,
  grades: gradesReducer,
  feedback: feedbackReducer,
  personalNotes: personalNotesReducer,
  gradingModels: gradingModelsReducer,
  badges:badgesReducer,
  snackbar:snackbarReducer,
  classAnnouncements: classAnnouncementsReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
