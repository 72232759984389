import * as actionTypes from '../../actions/personal-information/password/actionTypes'
import produce from 'immer'
import { inputChanged } from '../../../utility/forms/inputChanged'

const initialState = {
    form: {
        controls: {            
            oldPassword: {
                label: "old Password",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'oldPassword',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            newPassword: {
                label: "New Password",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'New Password',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            RepeatNewPassword: {
                label: "Repeat New Password",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Repeat New Password',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
        },
        isValid: false,
        error: null
    },
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.INPUT_VALUE_CHANGED_PASSWORDUPDATE_UPDATE):
                // update the input value in the state
                inputChanged(draft, action.name, action.value)
                break

    


            case(actionTypes.PUT_PASSWORDUPDATE_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.PUT_PASSWORDUPDATE_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.PUT_PASSWORDUPDATE_SUCCESS):
                draft.loading = false
                break

            default:
        }
    })

export default reducer
