import * as actionTypes from "../../actions/personal-information/general-information/actionTypes";
import produce from "immer";
import { inputChanged } from "../../../utility/forms/inputChanged";

const initialState = {
  form: {
    controls: {
      username: {
        label: "Username",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
      },
      emailSchool: {
        label: "Email school",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
        disabled: true,
      },
      emailPrivate: {
        label: "Email private",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
      },
      accountState: {
        label: "Account state",
        value: null,
        inputType: "select",
        type: "text",
        validators: {},
        options: [
          {
            text: "Active",
            value: "Active",
          },
          {
            text: "Inactive",
            value: "Inactive",
          },
        ],
        valid: false,
        touched: false,
        disabled: true,
      },
      social_id: {
        label: "Social ID",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
        disabled: true,
      },
      signupDate: {
        label: "Signup date",
        value: null,
        inputType: "input",
        type: "date",
        validators: {},
        valid: false,
        touched: false,
        disabled: true,
      },
      lastSeenDate: {
        label: "Last seen",
        value: null,
        inputType: "input",
        type: "date",
        validators: {},
        valid: false,
        touched: false,
        disabled: true,
      },
      firstName: {
        label: "First name",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
      },
      lastName: {
        label: "Last name",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
      },
      mobile: {
        label: "Mobile",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
      },
      address: {
        label: "Address",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
      },
      postalCode: {
        label: "Postal code",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
      },
      dateOfBirth: {
        label: "Date of birth",
        value: "",
        inputType: "input",
        type: "date",
        validators: {},
        valid: false,
        touched: false,
      },
      nationality: {
        label: "Nationality",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
      },
      gender: {
        label: "Gender",
        value: "null",
        inputType: "select",
        validators: {},
        valid: false,
        touched: false,
        options: [
          {
            text: "Male",
            value: "Male",
          },
          {
            text: "Female",
            value: "Female",
          },
        ],
      },
      pcn: {
        label: "PCN",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
        disabled: false,
      },
      bio: {
        label: "BIO",
        value: null,
        inputType: "input",
        type: "text",
        validators: {},
        valid: false,
        touched: false,
        disabled: false,
      },
      privacy: {
        label: "privacy",
        value: null,
        inputType: "input",
        type: "Boolean",
        validators: {},
        valid: false,
        touched: false,
        disabled: false,
      },
    },
    isValid: false,
    error: null,
  },
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.INPUT_VALUE_CHANGED_GENERAL_INFORMATION:
        // update the input value in the state
        inputChanged(draft, action.name, action.value);
        break;

      case actionTypes.GET_GENERAL_INFORMATION_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.GET_GENERAL_INFORMATION_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_GENERAL_INFORMATION_SUCCESS:
        draft.loading = false;

        // populate state form controls
        Object.keys(action.info).forEach((controlName) => {
          draft.form.controls[controlName].value = action.info[controlName]
            ? action.info[controlName]
            : null;
        });
        break;

      case actionTypes.DELETE_GENERAL_INFORMATION_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.DELETE_GENERAL_INFORMATION_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.DELETE_GENERAL_INFORMATION_SUCCESS:
        draft.loading = false;
        draft.form.controls = {};
        break;

      case actionTypes.PUT_GENERAL_INFORMATION_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.PUT_GENERAL_INFORMATION_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.PUT_GENERAL_INFORMATION_SUCCESS:
        draft.loading = false;

        // populate state form controls
        Object.keys(action.info).forEach((controlName) => {
          draft.form.controls[controlName].value = action.info[controlName]
            ? action.info[controlName]
            : null;
        });
        break;

      default:
    }
  });

export default reducer;
