import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const deleteUser = (userID, id) => {
    return dispatch => {
        dispatch(deleteUserStart())

        axios.delete(`/user/${userID}`, {data: {id: id}})
            .then(response => {
                dispatch(deleteUserSuccess({...response.data.name}))
            })
            .catch(error => {
                dispatch(deleteUserFail(error.response.data.message))
            })
    }
}

export const deleteUserStart = () => {
    return {
        type: actionTypes.DELETE_USER_START
    }
}

export const deleteUserFail = (error) => {
    return {
        type: actionTypes.DELETE_USER_FAIL,
        error: error
    }
}

export const deleteUserSuccess = (id) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        id: id     
    }
}