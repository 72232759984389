import React from 'react';
import css from './Col.module.scss'

const col = (props) => {
    const colStyles = [css.Col]
    if (props.equal) {
        colStyles.push(css.Equal)
    }

    return ( 
        <div className={colStyles.join(' ')}>
            {props.children}
        </div>
     );
}
 
export default col;