import * as actionTypes from '../../actions/personal-information/working-experience/actionTypes'
import produce from 'immer'
import { inputChanged } from '../../../utility/forms/inputChanged'

const initialState = {
    workingExperience: [],
    form: {
        controls: {            
            title: {
                label: "Job Title",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Job title',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            company: {
                label: "Company",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Company',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            country: {
                label: "Country",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Country',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            city: {
                label: "City",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'City',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            startDate: {
                label: "Start Date",
                value: null,
                inputType: 'input',
                type: 'date',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            endDate: {
                label: "End Date",
                value: null,
                inputType: 'input',
                type: 'date',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            }
        },
        isValid: false,
        error: null
    },
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.INPUT_VALUE_CHANGED_WORKING_EXPERIENCE_UPDATE):
                // update the input value in the state
                inputChanged(draft, action.name, action.value)
                // also update the current value of the working experience in the array of working experience
                const updatedWorkingExperience = draft.workingExperience.find(exp => exp.id === action.id)
                updatedWorkingExperience[action.name] = action.value
                draft.workingExperience.forEach(experience => {
                    if (experience.id === action.id) experience = updatedWorkingExperience
                } )
                break

            case(actionTypes.INPUT_VALUE_CHANGED_WORKING_EXPERIENCE_CREATE):
                inputChanged(draft, action.name, action.value)
                break

            case(actionTypes.GET_WORKING_EXPERIENCE_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_WORKING_EXPERIENCE_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_WORKING_EXPERIENCE_SUCCESS):
                draft.loading = false
                draft.workingExperience = action.workingExperience                
                break
            
            case(actionTypes.DELETE_WORKING_EXPERIENCE_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.DELETE_WORKING_EXPERIENCE_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.DELETE_WORKING_EXPERIENCE_SUCCESS):
                draft.loading = false
                draft.workingExperience = draft.workingExperience.filter(exp => exp.id !== action.id)
                break

            case(actionTypes.POST_WORKING_EXPERIENCE_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.POST_WORKING_EXPERIENCE_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.POST_WORKING_EXPERIENCE_SUCCESS):
                draft.loading = false
                draft.workingExperience.push(action.data)

                Object.keys(draft.form.controls).forEach(controlName => {
                    draft.form.controls[controlName].value=null
                })                
                break

            case(actionTypes.PUT_WORKING_EXPERIENCE_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.PUT_WORKING_EXPERIENCE_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.PUT_WORKING_EXPERIENCE_SUCCESS):
                draft.loading = false

                Object.keys(draft.form.controls).forEach(controlName => {
                    draft.form.controls[controlName].value = null
                })      
                break

            default:
        }
    })

export default reducer
