import * as actionTypes from '../../actions/study-paths/actionTypes'
import produce from 'immer'
import { inputChanged } from '../../../utility/forms/inputChanged'

const initialState = {
    studyPaths: [],
    form: {
        controls: {            
            code: {
                label: "Code",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Code',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            name: {
                label: "Name",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Name',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            description: {
                label: "Description",
                value: null,
                inputType: 'textarea',
                type: 'textarea',
                placeholder: 'Description',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            nrOfSemesters: {
                label: "Number Of Semesters",
                value: null,
                inputType: 'text',
                type: 'text',
                placeholder: 'Semesters',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            firstYearDes: {
                label: "FirstYear",
                value: null,
                inputType: 'textarea',
                type: 'textarea',
                placeholder: 'firstYear',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            secondYearDes: {
                label: "SecondYear",
                value: null,
                inputType: 'textarea',
                type: 'textarea',
                placeholder: 'SecondYear',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            thirdYearDes: {
                label: "ThirdYear",
                value: null,
                inputType: 'textarea',
                type: 'textarea',
                placeholder: 'ThirdYear',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            fourthYearDes: {
                label: "FourthYear",
                value: null,
                inputType: 'textarea',
                type: 'textarea',
                placeholder: 'FourthYear',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            modules: {
                label: "modules",
                value: [],
                inputType: 'multiselect',
                type: 'multiselect',
              
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },


        },
        isValid: false,
        error: null
    },
    error: null,
    loading: null,
    deleting: false,
    editing: false,
    adding: false,
  
    
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            
            case(actionTypes.INPUT_VALUE_CHANGED_STUDY_PATHS_UPDATE):
                // update the input value in the state
                inputChanged(draft, action.name, action.value)
                // also update the current value of the working experience in the array of working experience
                const updatedstudyPaths = draft.studyPaths.find(exp => exp.id === action.id)
                updatedstudyPaths[action.name] = action.value
                draft.studyPaths.forEach(temp => {
                    if (temp.id === action.id) temp = updatedstudyPaths
                } )
            break

            case(actionTypes.INPUT_VALUE_CHANGED_STUDY_PATHS_CREATE):
                inputChanged(draft, action.name, action.value)
            break

            case actionTypes.GET_STUDY_PATHS_START:
                draft.loading = true;
                draft.error = null;
                break;
    
            case actionTypes.GET_STUDY_PATHS_FAIL:
                draft.loading = false;
                draft.error = action.error;
                break;

            case actionTypes.GET_STUDY_PATHS_SUCCESS:
                draft.loading = true;
                draft.studyPaths = action.studyPaths
                break;
            default:
            
                case(actionTypes.DELETE_STUDY_PATHS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.DELETE_STUDY_PATHS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.DELETE_STUDY_PATHS_SUCCESS):
                draft.deleting = true;
                draft.loading = false
                if(draft.studyPaths){
                    draft.studyPaths = draft.studyPaths.filter(exp => exp.id !== action.id)
                }
                break

            case(actionTypes.POST_STUDY_PATHS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.POST_STUDY_PATHS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.POST_STUDY_PATHS_SUCCESS):
                draft.adding = true
                draft.loading = false
                if(action.data){
                    draft.studyPaths.push(action.data)
                }
                if(draft.form){
                    Object.keys(draft.form.controls).forEach(controlName => {
                        draft.form.controls[controlName].value=null
                    })
                }                
                break

            case(actionTypes.PUT_STUDY_PATHS_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.PUT_STUDY_PATHS_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.PUT_STUDY_PATHS_SUCCESS):
                draft.editing = true
                draft.loading = false
                if(draft.form){
                    Object.keys(draft.form.controls).forEach(controlName => {
                        draft.form.controls[controlName].value = null
                    })  
                }    
                break


        }
    })

 
export default reducer;