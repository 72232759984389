import * as actionTypes from '../../actions/personal-information/previous-education/actionTypes'
import produce from 'immer'
import { inputChanged } from '../../../utility/forms/inputChanged'

const initialState = {
    previousEducation: [],
    form: {
        controls: {            
            title: {
                label: "Programme",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Job title',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            school: {
                label: "School",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'School',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            country: {
                label: "Country",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'Country',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            city: {
                label: "City",
                value: null,
                inputType: 'input',
                type: 'text',
                placeholder: 'City',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            startDate: {
                label: "Start Date",
                value: null,
                inputType: 'input',
                type: 'date',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            },
            endDate: {
                label: "End Date",
                value: null,
                inputType: 'input',
                type: 'date',
                validators: {
                    
                },
                valid: false,
                touched: false,
                required: true
            }
        },
        isValid: false,
        error: null
    },
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case(actionTypes.INPUT_VALUE_CHANGED_PREVIOUS_EDUCATION_UPDATE):
                // update the input value in the state
                inputChanged(draft, action.name, action.value)
                // also update the current value of the working education in the array of working education
                const updatedPreviousEducation = draft.previousEducation.find(edu => edu.id === action.id)
                updatedPreviousEducation[action.name] = action.value
                draft.previousEducation.forEach(education => {
                    if (education.id === action.id) education = updatedPreviousEducation
                } )
                break

            case(actionTypes.INPUT_VALUE_CHANGED_PREVIOUS_EDUCATION_CREATE):
                inputChanged(draft, action.name, action.value)
                break

            case(actionTypes.GET_PREVIOUS_EDUCATION_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.GET_PREVIOUS_EDUCATION_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.GET_PREVIOUS_EDUCATION_SUCCESS):
                draft.loading = false
                draft.previousEducation = action.previousEducation                
                break
            
            case(actionTypes.DELETE_PREVIOUS_EDUCATION_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.DELETE_PREVIOUS_EDUCATION_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.DELETE_PREVIOUS_EDUCATION_SUCCESS):
                draft.loading = false
                draft.previousEducation = draft.previousEducation.filter(edu => edu.id !== action.id)
                break

            case(actionTypes.POST_PREVIOUS_EDUCATION_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.POST_PREVIOUS_EDUCATION_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.POST_PREVIOUS_EDUCATION_SUCCESS):
                draft.loading = false
                draft.previousEducation.push(action.data)

                Object.keys(draft.form.controls).forEach(controlName => {
                    draft.form.controls[controlName].value=null
                })                
                break

            case(actionTypes.PUT_PREVIOUS_EDUCATION_START):
                draft.loading = true
                draft.error = null
                break
            case(actionTypes.PUT_PREVIOUS_EDUCATION_FAIL):
                draft.loading = false
                draft.error = action.error
                break
            case(actionTypes.PUT_PREVIOUS_EDUCATION_SUCCESS):
                draft.loading = false

                Object.keys(draft.form.controls).forEach(controlName => {
                    draft.form.controls[controlName].value = null
                })      
                break

            default:
        }
    })

export default reducer
