import React from 'react';
import css from './CardIntro.module.scss';
import {AiFillApple, AiFillAndroid,AiFillWindows} from  "react-icons/all";
// variables
import landing1 from '../../../../assets/images/landing.jpg'

const cardIntro = (props) => {

    // let footer = null
    // if (props.footer) {
    //     footer = (
    //         <footer className={css.title}>
    //             <h1>{props.footer}</h1>
    //         </footer>
    //     )
    // }

    return (
        <div className={css.CardIntro} style={props.style}>
          
            <img className={css.coverimg} src={landing1} alt="picture of staff and students" ></img>
            <div className={css.featureArea}>
            <div className={css.featuresBox}>
                  <h3> AIM will be  available on all the platfroms soon. </h3>
                  <div class={css.featureAppIcon}>
                        <a class={css.featureIconLeft} href="#"><AiFillApple/></a>
                        <a class={css.featureIconMiddle} href="#"><AiFillAndroid/></a>
                        <a class={css.featureIconRight} href="#"><AiFillWindows/></a>
                    </div>
            </div>
            </div>
{/*            
            <div >
                <h1>
                    Educational platform
                </h1>
                <h3>
                    For Student, Institute and curriculum management.
                </h3>
                <p>
                    AIM is a tool to manage your educational institute in the most effective way. AIM offers
                    a customizable dashboard for students and employees to plan their task and keep track of everything.
                    AIM is your personal assistant that will automate your repetitive tasks.
                </p>
            </div>


            <div >
                <img  src={landing1} alt="picture of staff and students" ></img>
            </div> */}

        </div>
    )
}

export default cardIntro;