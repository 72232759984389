import React, { Component } from 'react';
import css from './Home.module.scss';
import CardIntro from '../Home/CardIntro/CardIntro'
import CardAIMDes from '../Home/CardAIMDes/CardAIMDes'
import Footer from '../Home/Footer/Footer'
import CardContact from '../Home/CardContact/CardContact'
import CardVideo from '../Home/CardVideo/CardVideo'
import CardTabs from '../Home/CardTabs/CardTabs'
import Carousels from '../Home/Carousels/Carousels'
class Home extends Component {
    render() { 
        return ( 

            <div className={[css.Home, css.CardLandingPage, css.CardIntro].join(' ')}>
                <CardIntro title="Intro Card!"> </CardIntro>
                <CardTabs></CardTabs>
                <CardVideo> </CardVideo>
                <Carousels> </Carousels>
                <CardContact></CardContact>
                <Footer> </Footer> 

            </div>            
        );
    }
}
 
export default Home;